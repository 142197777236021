export default function authHeader() {
  let access_token = JSON.parse(localStorage.getItem('access_token'));

  if (access_token) {
    // console.log('auth', access_token)
    return { Authorization: 'Bearer ' + access_token };
  } else {
    // console.log('auth', 'none')
    return {};
  }
}
