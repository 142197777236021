import axios from 'axios';
import authHeader from './auth-header';
import track from '@/helpers/google-analytics-tracker';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    sendCode(form) {
        console.log('auth.service.send_code.form', form);
        return axios
            .post(API_URL + '/wts/send_code', {
                email: form.email,
                code: form.code,
                authType: form.authType,
                id_token: form.id_token,
                state: form.state,
            })
            .then(response => {
                console.log('auth.service.send_code.response', response);
                return Promise.resolve(response);
            });
    }

    login(form) {
        console.log('auth.service.login.form', form);
        return axios
            .post(API_URL + '/wts/signin', {
                email: form.email,
                pin_code: form.pin_code
            }).then(response => {
                console.log('auth.service.login.response', response);
                localStorage.setItem('user', JSON.stringify(response.data.customer));
                localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
                return Promise.resolve(response);
            });
    }

    getSubscription() {
        console.log('auth.service.getSubscription');
        return axios
            .get(API_URL + '/wts/get_subscription', { headers: authHeader() }).then(response => {
                console.log('auth.service.getSubscription.response', response);
                localStorage.setItem('user', JSON.stringify(response.data.customer));
                return Promise.resolve(response);
            });
    }

    subscribe(form) {
        console.log('auth.service.subscribe.form', form);
        return axios
            .post(API_URL + '/wts/subscribe', {
                period: form.period
            }, { headers: authHeader() })
            .then(response => {
                console.log('auth.service.subscribe.response', response);
                return Promise.resolve(response);
            });
    }

    checkoutCompleted(sessionId) {
        console.log('auth.service.checkoutCompleted');
        return axios
            .get(`${API_URL}/account/checkout_completed/${sessionId}`, { headers: authHeader() })
            .then(resp => {
                console.log('auth.service.checkoutCompleted', resp);
                return Promise.resolve(resp);
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        track('authentication', { type: 'logout' });
    }

    appleAuth(form) {
        console.log('auth.service.appleAuth');
        return axios
            .post(API_URL + '/wts/apple_auth', {
                code: form.code,
                id_token: form.id_token,
                state: form.state,
                nonce: form.nonce,
            })
            .then(response => {
                console.log('auth.service.appleAuth.response', response);
                localStorage.setItem('user', JSON.stringify(response.data.customer));
                localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
                return Promise.resolve(response);
            });
    }

    googleAuth(form) {
        console.log('auth.service.googleAuth');
        return axios
            .post(API_URL + '/wts/google_auth', {
                credential: form.credential,
                is_wts_ios_app: form.is_wts_ios_app
            })
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data.customer));
                localStorage.setItem('access_token', JSON.stringify(response.data.access_token));
                return Promise.resolve(response);
            });
    }
}

export default new AuthService();
