<template>
  <div />
</template>

<script>
import { trackPurchase, trackError } from "@/helpers/google-analytics-tracker";

export default {
  name: "ThanksForSubscribing",
  props: {
    givenSessionId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      email: null,
      purchase: null,
      sessionId: this.givenSessionId,
    }
  },
  watch: {
    purchase: {
      handler: function () {
        this.trackPurchase();
      },
      deep: true,
    }
  },
  async mounted() {
    const { sessionId } = this;

    try {
      const resp = await this.$store.dispatch("auth/checkoutCompleted", sessionId)
      const { purchase, customer } = resp.data;
      this.purchase = purchase;
      this.email = customer?.email;

      if (this.email === null || this.email.trim() === "") {
        this.$store.commit('setSuccessAlertMsg', "Thanks for subscribing!");
      } else {
        this.$store.commit('setSuccessAlertMsg', "Thanks for subscribing, " + this.email + "!");
      }

    } catch (e) {
      trackError(e);
      console.log("Error getting data")
      trackError(e)
    }

  },
  methods: {
    trackPurchase() {
      if (!this.purchase) {
        return;
      }
      else {
        const {
          currency,
          transaction_id,
          value,
          items,
        } = this.purchase;

        trackPurchase({
          currency,
          transaction_id,
          value,
          items,
        })
      }
    }
  }
}
</script>
