export default (function createDebounce() {
  let timer = null;
  return function(fn, delay=500, ...args) {
    console.log("debounce return function");
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}())
