<template>
  <div
    :style="{ visibility: loading_feature_info ? 'hidden' : 'visible' }"
    class="text-center"
  >
    <div
      v-if="(is_ios_app_ua && !is_newer_ios_app) || (is_ios_app_ua && !updatedSubscribeFlowEnabled) "
      class="mx-1"
    >
      <h1>You're Almost There!</h1>
      <p class="text-start">
        You just need to choose a subscription plan to unlock access to the app.
      </p>
      <ol class="text-start mb-3">
        <li @click="copyToClipboard()">
          Visit <strong>{{ app_url }}</strong> <font-awesome-icon icon="copy" />
        </li>
        <li>Choose a subscription plan</li>
        <li>Come back here and unlock access</li>
      </ol>

      <button
        class="btn btn-primary"
        @click="checkSubscription()"
      >
        Unlock Access
      </button>
    </div>
    <div v-else-if="updatedSubscribeFlowEnabled && !isStudentPlanSelected">
      <video-player :options="videoOptions" />
      <h1 class="h1-wts pt-4 text-center">
        Choose your plan.
      </h1>
      <ul class="text-start">
        <li class="li-wts">
          No strings attached, cancel anytime.
        </li>
        <li class="li-wts">
          Unlimited access to library with new videos monthly.
        </li>
        <li class="li-wts">
          Immerse yourself in endless learning by watching "What’s the Sign?" on all devices.
        </li>
      </ul>
      <p class="trial-text text-center pt-4">
        $4.99 per month <br>
        Search for as many words/phrases as you want, anytime!
      </p>
      <Form @submit="subscribe">
        <Field
          type="hidden"
          name="period"
          value="month"
        />
        <div class="text-center">
          <button
            class="btn-wts btn-primary"
            @click="changePlan('monthly')"
          >
            Start 3-Day Free Trial
          </button>
        </div>
      </Form>

      <p class="text-center trial-text pt-4">
        $49.99 per year <br>
        Unlock unlimited searches for a year with one quick payment!
      </p>
      <Form @submit="subscribe">
        <Field
          type="hidden"
          name="period"
          value="year"
        />
        <div class="text-center">
          <button
            class="btn-wts btn-primary"
            @click="changePlan('yearly')"
          >
            Start 3-Day Free Trial
          </button>
        </div>
      </Form>
    </div>
    
    <div
      v-else
      class="row"
    >
      <div
        v-if="!isStudentPlanSelected"
        class="col-xs-12 col-md-6 col-lg-6"
      >
        <Form @submit="subscribe">
          <Field
            type="hidden"
            name="period"
            value="month"
          />
          <div
            class="card mb-4"
            @click="changePlan('monthly')"
          >
            <div
              class="card-body plan"
              :class="{ 'plan-active': isMonthlyPlanSelected }"
              :aria-pressed="isMonthlyPlanSelected"
            >
              <h5 class="card-title">
                $4.99 per month
              </h5>
              <p class="card-text">
                Search for as many words/phrases as you want, anytime!
              </p>
              <button
                class="btn btn-primary btn-block"
                :disabled="loading"
              >
                Start 3-Day Free Trial
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div
        v-if="!isStudentPlanSelected"
        class="col-xs-12 col-md-6 col-lg-6"
      >
        <Form @submit="subscribe">
          <Field
            type="hidden"
            name="period"
            value="year"
          />
          <div
            class="card"
            @click="changePlan('yearly')"
          >
            <div
              class="card-body plan"
              :class="{ 'plan-active': isAnnualPlanSelected }"
              :aria-pressed="isAnnualPlanSelected"
            >
              <h5 class="card-title">
                $49.99 per year
              </h5>
              <p class="card-text">
                Unlock unlimited searches for a year with one quick payment!
              </p>
              <button
                class=" btn btn-primary btn-block"
                :disabled="loading"
              >
                Start 3-Day Free Trial
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div v-if="isStudentPlanSelected">
        <Form @submit="subscribe">
          <Field
            type="hidden"
            name="period"
            value="student"
          />
          <div
            class="card"
            @click="changePlan('student')"
          >
            <div class="card-body plan">
              <h5 class="card-title">
                $15.99 per 4 months
              </h5>
              <p class="card-text">
                Unlock unlimited searches for four months with one quick payment!
              </p>
              <button
                class=" btn btn-primary btn-block"
                :disabled="loading"
              >
                Buy
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import VideoPlayer from '@/components/VideoPlayer.vue';
import { trackSelectItem, trackBeginCheckout } from "@/helpers/google-analytics-tracker";
import { isIosAppUserAgent, isNewerIosApp } from "@/helpers/ios-app";
import { createSignedUrl } from "@/helpers/sprout-video";
import FeatureService from '@/services/feature.service';

const AVAILABLE_PLANS = ['monthly', 'yearly', 'student'];
const PRICINGS = [4.99, 49.99, 15.99]

export default {
  name: "Subscribe",
  components: {
    Form,
    Field,
    VideoPlayer,
  },
  data() {
    return {
      loading: false,
      show_form: 'verify_email',
      message: "",
      selected_plan: "",
      app_url: window.location.host,
      updatedSubscribeFlowEnabled: false,
      is_ios_app_ua: this.isIosAppUserAgent(),
      is_newer_ios_app: this.isNewerIosApp(),
      loading_feature_info: true,
      videoOptions: {
        autoplay: true,
        controls: true,
        loop: true,
        fluid: true,
        playsinline: true,
        muted: true,
        sources: [
          {
            src:
              this.createSignedUrl(),
              type: 'video/mp4',
          },
        ],
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSubscribed() {
      return this.$store.state.auth.user.is_subscribed;
    },
    isMonthlyPlanSelected() {
      return this.selected_plan === 'monthly';
    },
    isAnnualPlanSelected() {
      return this.selected_plan === 'yearly';
    },
    isStudentPlanSelected() {
      return this.selected_plan === 'student';
    },
  },

  mounted() {
    this.setPlanFromQuery();
  },

  async created() {
    if (this.isIosAppUserAgent() && this.isNewerIosApp() && this.loggedIn) {
      console.log("going to landing page");
      this.$router.push("/ioslanding");
    }
    if (this.loggedIn) {
      if (this.isSubscribed) {
        this.$router.push("/search");
      }
    } else {
      localStorage.setItem('redirectPath', this.$route.fullPath);
      this.$router.push("/login")
    }

    try {
      let result = await FeatureService.isEnabled("updated_subscribe_flow");
      this.updatedSubscribeFlowEnabled = result;
      this.loading_feature_info = false;

    } catch (error) {
      console.error("An error occurred while checking feature:", error);
    } finally {
      this.loading_feature_info = false;
    }
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.app_url);
        alert('Copied to clipboard');
      } catch (err) {
        alert('Failed to copy');
      }
    },
    subscribe(form) {
      console.log('subscribe.form', form)

      const { selected_plan } = this;
      const idx = AVAILABLE_PLANS.find((p) => p == selected_plan)
      trackBeginCheckout({
        currency: "USD",
        value: PRICINGS[idx],
        items: [
          {
            item_id: selected_plan,
            item_name: selected_plan,
            item_brand: "TrueWayASL",
            item_category: "Education",
            item_category2: "Subscription",
          }
        ],
      });

      this.$store.dispatch("auth/subscribe", form).then(
        (response) => {
          console.log('response', response);
          window.location.href = response.data.checkout_url;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    setPlanFromQuery() {
      const plan = this.$route.query.plan;
      trackSelectItem({
        itemsListId: "subscription-plans",
        itemsListName: "Plan Subscription Chooser",
        items: [{
          item_id: plan,
          item_name: plan,
        }],
      });

      if (AVAILABLE_PLANS.includes(plan)) {
        this.selected_plan = plan;
      }
    },
    changePlan(plan) {
      this.selected_plan = plan;

      trackSelectItem({
        itemsListId: "subscription-plans",
        itemsListName: "Plan Subscription Chooser",
        items: [{
          item_id: plan,
          item_name: plan,
        }],
      });

      this.$router.replace({ query: { plan: plan } })
    },

    checkSubscription() {
      this.$store.dispatch("auth/getSubscription").then(
        () => {
          if (this.isSubscribed) {
            // do nothing
            console.log("user is subscribed")
            this.$router.push("/search")
          } else {
            this.$store.commit('setErrorAlertMsg', "It looks like you didn't choose a subscription plan.");
            this.$router.push("/subscribe")
          }
        },
        (error) => {
          console.log(error.toString())
          this.$store.commit('setErrorAlertMsg', "It looks like you didn't choose a subscription plan.");
          this.$router.push("/subscribe")
        }
      )
    },
    isIosAppUserAgent() {
      return isIosAppUserAgent();
    },

    isNewerIosApp() {
      return isNewerIosApp();
    },
    createSignedUrl() { 
      return createSignedUrl("4d90d5b31916efc5c4", "6c5e80888de13163"); 
    },
  },
};
</script>

<style lang="css" scoped src="../../public/wts.css" />
<style scoped>
.plan {
  border: 2px solid transparent;
}

.plan-active {
  border: 2px solid #5E2B81;
}

.h1-wts {
  white-space: nowrap;
}

.btn-wts {
  width: 77%;
}

</style>
