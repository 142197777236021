<template>
  <div
    v-if="successAlertMsg"
    class="alert alert-success alert-dismissible fade show"
    role="alert"
  >
    {{ successAlertMsg }}
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="dismissSuccessAlertMsg"
    />
  </div>
</template>
  
<script>

export default {
    computed: {
        successAlertMsg() {
            return this.$store.getters.successAlertMsg;
        },
    },
    methods: {
        dismissSuccessAlertMsg() {
            this.$store.commit('setSuccessAlertMsg', "");
        },
    },

}
</script>
  