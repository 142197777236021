import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        sendCode({commit}, form) {
            console.log('auth.module.send_code.form', form);
            return AuthService.sendCode(form).then(
                response => {
                    console.log('auth.module.send_code.response', response);
                    commit('sendCodeSuccess', response.data.customer);
                    return Promise.resolve(response);
                },
                error => {
                    commit('sendCodeFailure');
                    return Promise.reject(error);
                }
            );
        },
        login({commit}, form) {
            console.log('auth.module.login.form', form);
            return AuthService.login(form).then(
                response => {
                    console.log('auth.module.login.response', response);
                    commit('loginSuccess', response.data);
                    return Promise.resolve(response);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        getSubscription({commit}) {
            console.log('auth.module.getSubscription');
            return AuthService.getSubscription().then(
                response => {
                    console.log('auth.module.getSubscription.response', response);
                    let customer = response.data.customer;
                    commit('subscriptionSuccess', customer);
                    return Promise.resolve(response);
                },
                error => {
                    commit('subscriptionFailure');
                    localStorage.removeItem('user');
                    return Promise.reject(error);
                }
            );
        },
        subscribe({commit}, form) {
            console.log('auth.module.subscribe.form', form);
            return AuthService.subscribe(form).then(
                response => {
                    console.log('auth.module.subscribe.response', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('subscriptionFailure');
                    return Promise.reject(error);
                }
            );
        },
        checkoutCompleted({commit}, sessionId) {
            console.log('auth.module.checkoutCompleted');
            return AuthService.checkoutCompleted(sessionId).then(
                response => {
                    console.log('auth.module.checkoutCompleted.response', response);
                    commit('thanksSuccess');
                    return Promise.resolve(response);
                },
                error => {
                    commit('thanksFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        appleAuth({commit}, form) {
            console.log('auth.module.appleAuth');
            return AuthService.appleAuth(form).then(
                response => {
                    console.log('auth.module.appleAuth', response);
                    commit('appleAuthSuccess', response.data);
                    return Promise.resolve(response);
                },
                error => {
                    commit('appleAuthFailure');
                    return Promise.reject(error);
                }
            );
        },
        googleAuth({commit}, form) {
            console.log('auth.module.googleAuth');
            return AuthService.googleAuth(form).then(
                response => {
                    commit('googleAuthSuccess', response.data);
                    return Promise.resolve(response);
                },
                error => {
                    commit('googleAuthFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        sendCodeSuccess(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        sendCodeFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        loginSuccess(state, data) {
            console.log('auth.module.commit.login', data);
            state.status.loggedIn = true;
            state.user = data.user || data.customer;
            state.access_token = data.access_token;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        subscriptionSuccess(state, data) {
            state.user.is_subscribed = data.is_subscribed;
        },
        subscriptionFailure(state) {
            state.status.loggedIn = false;
            state.user.is_subscribed = false;
        },
        thanksSuccess(state) {
            state.thanked = true;
        },
        thanksFailure(state) {
            delete state.thanked;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        appleAuthSuccess(state, data) {
            state.status.loggedIn = true;
            state.user = data.user || data.customer;
            state.access_token = data.access_token;
        },
        appleAuthFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },

        googleAuthSuccess(state, data) {
            state.status.loggedIn = true;
            state.user = data.user || data.customer;
            state.access_token = data.access_token;
        },
        googleAuthFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    }
};
