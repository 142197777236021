<template>
  <div>
    <div>
      <h1 class="border-bottom mb-4">
        FAQ
      </h1>
      <div
        v-for="qa in questions "
        :key="qa.id"
      >
        <h5
          v-if="qa.slug"
          :id="qa.slug"
        >
          <!-- eslint-disable -->
          <a :id="qa.slug" :href="'#' + qa.slug" class="text-decoration-none text-reset" v-html="qa.question" />
          <!-- eslint-enable -->
        </h5>
        <!-- eslint-disable -->
        <h5 v-else v-html="qa.question" />
        <!-- eslint-enable -->
        <!-- eslint-disable -->
        <!-- warning: 'v-html' directive can lead to XSS attack -->
        <p class="pb-1" v-html="qa.answer" />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    let questions =
      [
        {
          // do not change these slugIds
          idForSlug: 1,
          question: `I am a student taking an ASL course. Will <i>What’s the Sign?</i> help me pass the course?!`,
          answer: `You lucked out! <i>What’s the Sign?</i> Is actually geared for ASL students! The vocabulary covered inside the app is aligned with the majority of ASL textbooks nationwide. You’ll have to do the studying and the practicing, though. We’re rooting for you!`
        },
        {
          idForSlug: 2,
          question: `I am a student taking an ASL course using the TRUE+WAY ASL textbook. There’s a <i>What’s the Sign?</i> inside my course. Is it the same thing?`,
          answer: `The content of the <i>What’s the Sign?</i> dictionary link provided inside the TRUE+WAY ASL textbook is similar to <i>What’s the Sign?</i> found here. The main difference is that this version of <i>What’s the Sign?</i> is mobile-friendly! Some students choose to purchase a subscription to <i>What’s the Sign?</i> in addition to their ASL textbook, which is absolutely fine.`
        },
        {
          idForSlug: 3,
          question: `I am not an ASL student, but someone who wants to learn and keep up my ASL skills. Is <i>What’s the Sign?</i> a good place to do this?`,
          answer: `Absolutely! <i>What’s the Sign?</i> is for everyone - from those just starting out, to certified, seasoned interpreters, children of Deaf adults, and more!  We at <i>What’s the Sign?</i> are a fully-Deaf team and we often check the app for a sign here and there from time to time. Just like English, we look up words on a daily basis. It’s the same for ASL! Have this app handy, ready, at your fingertips for whenever you need to look up a sign!`
        },
        {
          idForSlug: 4,
          question: `I’m an interpreter/CODA/Deaf person. I already know ASL. Will I benefit from <i>What’s the Sign?</i>`,
          answer: `Yes! Being fluent in a language doesn’t mean that you don’t need a dictionary in that language! Do you know the ASL sign for CRYPTOCURRENCY? Can you sign out all the different regional variations for CHEAT? Try our <a href="https://app.whatsthesign.com/">3-day trial</a> and see how many signs you learn from our dictionary!`
        },
        {
          idForSlug: 5,
          question: `I’m in (insert country here). Will <i>What’s the Sign?</i> help me communicate with Deaf people here?`,
          answer: `<i>What’s the Sign?</i> is specifically for American Sign Language (ASL), which is used in the United States and Canada.  It’s a good idea to seek out the Deaf community in your country and learn their sign language. Yes, there are hundreds of different sign languages all over the world! The United States and Canada also have quite a number of sign languages in addition to ASL as well. Examples include Plains Indian Sign Languages (PISL), Black ASL, Langue des signes du Québec (LSQ), Inuit Sign Language, Lengua de Señas Mexicana (LSM), and many more. Be sure to read: <a href="https://whatsthesign.com/where-did-american-sign-language-come-from/">"When and How Did American Sign Language Originate? The Story Behind ASL"</a> blog post.`
        },
        {
          idForSlug: 6,
          question: `How can I access <i>What’s the Sign?</i> on my mobile device? What if I can’t find it?`,
          answer: `If you’re an iOS user, you can download our iOS app from the <a href="https://apps.apple.com/us/app/whats-the-sign/id6465301116">App Store</a>. For Android users, <i>What’s the Sign?</i> is available as a web app accessible through any web browser. Simply visit <a href="https://app.whatsthesign.com/"> app.whatsthesign.com</a> to access the web app. For added convenience, you can make the web app look and feel like a native app on your Android device by following the instructions <a href="https://docs.google.com/document/d/e/2PACX-1vStmSqiGVsKuqnWvn9-ihLR7UPJ3L4NHU5grfVsutspnnXTu8Bo7NWNOiw-lDNWFp3XYJhJSEQxdOik/pub">linked here</a>.`
        },
        {
          idForSlug: 7,
          question: `I don’t want a subscription. I want to buy the dictionary. How do I do that?`,
          answer: `The reason why <i>What’s the Sign?</i> is subscription-based because the team is constantly adding new content and updating current content in the dictionary. Your subscription helps the <i>What’s the Sign?</i> continue to create new and exciting content based on the latest linguistics research and community creations!`
        },
        {
          idForSlug: 8,
          question: `Can I share my subscription with my family or friends to access <i>What’s the Sign?</i>`,
          answer: `<i>What’s the Sign?</i> was intentionally priced low for an individual subscriber. Please have your family and friends <a href="https://app.whatsthesign.com/subscribe?plan=monthly">subscribe</a> to <i>What’s the Sign?</i> and support the Deaf actors, Deaf authors and the Deaf community!`
        },
        {
          idForSlug: 9,
          slug: "0410",
          question: `If I stop my subscription for a while and come back later, will the price stay the same?`,
          answer: `Unfortunately we don’t have a crystal ball. We don’t know what the pricing will be like in the future. The only way to ensure your price is “locked” in is by becoming - and staying - as a subscriber - this locks in your monthly/annual subscription fee.`
        },
        {
          idForSlug: 10,
          question: `How do I cancel my subscription to <i>What’s the Sign?</i>`,
          answer: `Simply click on “Account” on the top right corner and under “Billing”, you’ll see your subscription details. Click on the gray box that says, “cancel subscription”. If you’re still having trouble, please send an email to <a href="mailto: hello@whatsthesign.com">hello@whatsthesign.com</a> and we will be able to assist. <br/><br/>When you cancel, your subscription will end after the last day of your pre-paid subscription. If you ever forget a sign or need to look one up again, we at <i>What’s the Sign?</i> will be delighted to have you back!
`
        },
        {
          idForSlug: 11,
          question: `I searched for a word or phrase and couldn’t find it in <i>What’s the Sign?</i> What should I do?`,
          answer: `Email hello@whatsthesign.com with the word or phrase you’re looking for! The <i>What’s the Sign?</i> team is continuously updating the dictionary.`
        },
        {
          idForSlug: 12,
          question: `What does “Variation #1” and “Variation #2” mean?`,
          answer: `As you might know, English has many words for the same concept, right? In the west and in the northeast, people speak “soda”, while in some parts of the United States, some people say “pop”. The same thing happens with ASL! The <i>What’s the Sign?</i> Team tries to gauge and identify the most widely known/used sign in the Deaf communities as Variation #1, and the lesser known ones as #2, #3 and so on. Choosing which variation you use will depend on the region in which you live and the Deaf community there. `
        },
        {
          idForSlug: 13,
          question: `Why are there Units listed under the videos? What are they for?`,
          answer: `Below the video, you’ll see something like: “Unit 2.2, 9.1”.  That tells you where the vocabulary/sign can be found inside the TRUE+WAY ASL textbook. If you don’t have the TRUE+WAY ASL textbook, that’s fine - the TRUE+WAY ASL Units are categorized by themes, such as food, destinations, scheduling, your home, people among us, families and relationships, sports, history, animals and many more!`
        },
        {
          idForSlug: 14,
          question: `I saw an ASL sign, but how do I find out what it means in English?`,
          answer: `Ooh! Great question. <i>What’s the Sign?</i> Team is working on a way to search for ASL signs - this exciting feature is coming soon!`
        }
      ];
    questions.forEach((qa, i) => qa.idForSlug = i + 1);
    return {
      questions,
    };
  },
  watch: {
    // Watch for changes in the route (e.g., navigating within the same component)
    $route: 'scrollToHash',
  },
  async mounted() {
    await this.generateSlugsForQuestions();
    this.scrollToHash();
  },

  methods: {
    async generateSlug(idForSlug) {
      // Convert the unique ID to a Uint8Array
      const encoder = new TextEncoder();
      const data = encoder.encode(idForSlug);

      // Generate a SHA-256 hash
      const buffer = await crypto.subtle.digest('SHA-256', data);

      // Convert the hash to a hexadecimal string
      const hashArray = Array.from(new Uint8Array(buffer));
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

      // Take the first 4 characters of the hash to create a slug
      const slug = 'a' + hashHex.substring(0, 4); // Add 'a' as a prefix
      return slug;
    },

    async getSlug(idForSlug) {
      const generatedSlug = await this.generateSlug(idForSlug);
      return generatedSlug;
    },
    async generateSlugsForQuestions() {
      for (const qa of this.questions) {
        qa.slug = await this.getSlug(qa.idForSlug);
      }
    },
    scrollToHash() {
      // Check if the route has a hash
      if (this.$route.hash) {
        // Extract the ID from the hash
        const id = this.$route.hash.slice(1); // Remove the leading '#'

        // Find the element with the matching ID
        const element = document.getElementById(id);

        // Scroll to the element if it exists
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
  },
}
</script>

<style scoped></style>
