import { nextTick } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import { isIosAppUserAgent } from "@/helpers/ios-app";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import Subscribe from "./components/Subscribe.vue";
import Search from "./components/Search.vue";
import Faq from "./components/Faq.vue";
import Account from "./components/Account.vue";
import IosLanding from "./components/IosLanding.vue";
import IosNew from "./components/IosNew.vue";

// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const Admin = {
  Dictionary: () => import("./components/admin/dictionary/Index.vue"),
  Category: () => import("@/components/admin/category/Index.vue"),
  Tag: () => import("@/components/admin/tag/Index.vue"),
};

const DEFAULT_TITLE = "What’s The Sign?™";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    meta: {
      title: "Home"
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/register",
    component: Register,
    meta: {
      title: "Register",
    },
  },
  {
    path: "/subscribe",
    component: Subscribe,
    meta: {
      title: "Subscribe",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      title: "FAQ",
    }
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      title: "Search",
    },
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      title: "Account",
    },
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/admin/dictionary",
    name: "admin_dictionary",
    component: Admin.Dictionary,
    meta: {
      title: "Edit Terms",
    },
  },
  {
    path: "/admin/category",
    name: "admin_category",
    component: Admin.Category,
    meta: {
      title: "Edit Categories",
    },
  },
  {
    path: "/admin/tag",
    name: "admin_tag",
    component: Admin.Tag,
    meta: {
      title: "Edit Tags",
    }
  },
  {
    path: "/ioslanding",
    component: IosLanding,
    meta: {
      title: "Landing"
    },
  },
  {
    path: "/new",
    component: IosNew,
    meta: {
      title: "New"
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/faq', '/ioslanding', '/new'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  const redirectPath = localStorage.getItem('redirectPath');
  if (publicPages.includes(redirectPath)) {
    localStorage.removeItem('redirectPath');
  }

  // redirect to login page
  if (authRequired && !loggedIn) {
    localStorage.setItem('redirectPath', to.fullPath)
    if ((isIosAppUserAgent() && (!loggedIn))) {
      next('/ioslanding');
    } else {
      next('/login');
    }
  } else {
    if (isIosAppUserAgent() && to.path == "/" && (!loggedIn)) {
      next('/ioslanding');
    }
    else {
      next();
    }
  }
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta?.title ? `${DEFAULT_TITLE} | ${to.meta.title}` : DEFAULT_TITLE;
  })
});

export default router;
