const crypto = require('crypto');

function createSignedUrl(videoId, securityToken) {
    var currentTimeMillis = new Date().getTime();
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    var futureTimeMillis = currentTimeMillis + ONE_DAY_IN_MS;
    var futureDate = new Date(futureTimeMillis);
    // Convert the future time to seconds since the Epoch (UTC)
    var expirationParameter = Math.floor(futureDate.getTime() / 1000);
    var hostName = "api-files.sproutvideo.com";
    var requestPath = "/file/" + videoId + "/" + securityToken + "/1080.mp4";
    var expiresQueryParam = "&expires=" + expirationParameter;
    var multiLineString = `GET
${hostName}
${requestPath}
${expiresQueryParam}`;
    // Calculate the signature
    const signature = calculateSignature(multiLineString, process.env.VUE_APP_SPROUT_VIDEO_KEY);
    // Convert the binary signature to base64
    const base64Signature = Buffer.from(signature, 'binary').toString('base64');
    var signatureQueryParam = "&signature=" + encodeURIComponent(base64Signature);
    const finalRequestUrl = "https://" + hostName + requestPath + "?"+ expiresQueryParam + signatureQueryParam;
    return finalRequestUrl;
}

// Function to calculate the HMAC-SHA1 signature
function calculateSignature(signatureBaseString, signingKey) {
    const hmac = crypto.createHmac('sha1', signingKey);
    hmac.update(signatureBaseString);
    return hmac.digest('binary');
}

export {
    createSignedUrl,
};