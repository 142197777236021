<template>
  <div>
    <h1 class="h1-wts text-center pb-2">
      Your Go-To ASL Dictionary!
    </h1>
    <video-player :options="videoOptions" />
    <h2 class="pt-4 text-center">
      25,000+ American Sign Language Signs
    </h2>
    <div class="my-4">
      <p
        class="acct-text text-center fs-5"
      >
        Create or Manage your <em>What’s the Sign?</em> account at <a
          :href="'https://' + new_url" 
          class="d-block"
        >https://{{ new_url }}</a>
      </p>
    </div>
    <div v-if="!loggedIn">
      <p class="p-wts text-center fs-5">
        Have an Account With Us?
      </p>

      <div
        class="text-center"
      >
        <button
          class="btn-wts btn btn-primary"
          @click="goToLoginPage()"
        >
          LOG IN
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import { createSignedUrl } from "@/helpers/sprout-video";

export default {
    name: "IosLanding",
    components: {
        VideoPlayer
    },
    data() {
        return {
            videoOptions: {
              autoplay: true,
                controls: true,
                loop: true,
                fluid: true,
                playsinline: true,
                muted: true,
                sources: [
                  {
                    src:
                      this.createSignedUrl(),
                      type: 'video/mp4',
                  },
                ],
            },
            new_url: window.location.host + "/new",
        };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSubscribed() {
      return this.$store.state.auth.user.is_subscribed;
    },

  }, 
  mounted() {
    if (this.loggedIn) {
      this.checkSubscription();
    }
    window.reloadFromIos = this.reloadFromIos.bind(this);
  },

  methods: {
    goToLoginPage() {
      this.$router.push('/login');
    },
    checkSubscription() {
      this.$store.dispatch("auth/getSubscription").then(
        () => {
          if (this.isSubscribed) {
            this.$router.push('/search');
          } 
        },
        // (_error) => {
        // }
      )
    },

    createSignedUrl() { 
      return createSignedUrl("d390d5b31916efc35a", "47a332a5cbb8facc"); 
    },

    // this function is called from Wts ios app.
    reloadFromIos() {
      console.log("reloadFromIos");
      if (this.loggedIn) {
        this.checkSubscription();
      }
    },
  }
};
</script>

<style lang="css" scoped src="../../public/wts.css"/>

<style scoped>
 h1{
  white-space: nowrap;
 }

 .btn-wts{
  width: 33.333%;
}

.acct-text {
    font-family: 'Proxima Nova Bold', Arial, sans-serif;
    color: #5F2C83;
}

</style>