<template>
  <div
    v-if="errorAlertMsg"
    class="alert alert-danger alert-dismissible fade show"
    role="alert"
  >
    {{ errorAlertMsg }}
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="dismissErrorAlertMsg"
    />
  </div>
</template>
    
<script>

export default {
    computed: {
        errorAlertMsg() {
            return this.$store.getters.errorAlertMsg;
        }
    },
    methods: {
        dismissErrorAlertMsg() {
            this.$store.commit('setErrorAlertMsg', "");
        }
    },

}
</script>
    