<template>
  <div>
    <video-player :options="videoOptions" />
    <h1 class="h1-wts pt-4 text-center">
      You're Almost There!
    </h1>
    <h4 class="pt-4 text-center">
      Create or Manage Your <em>What’s the Sign?</em> Account and Start Your Free 3-day Trial Today!
    </h4>

    <Form
      :validation-schema="email_schema"
      @submit="sendCode"
    >
      <div class="form-group d-flex flex-column align-items-center">
        <div
          v-if="!loggedIn"
          class="mb-3 pt-2"
          style="width: 90%;"
        >
          <Field
            name="email"
            type="email"
            placeholder="Enter your email address"
            style="width: 100%;"
          />
          <ErrorMessage
            name="email"
            class="text-danger"
          />
        </div>
        <div style="width: 90%;">
          <button
            v-if="!loggedIn"
            type="submit"
            class="btn-wts btn btn-primary"
            :disabled="showOtc"
          >
            START FREE TRIAL
          </button>
          <button
            v-else
            type="submit"
            class="btn-wts btn btn-primary"
            @click="goToSubscribe"
          >
            MANAGE ACCOUNT
          </button>
          <div
            v-if="showOtc"
            class="mt-4"
          >
            <p class="p-wts fs-5">
              To Confirm your email address, we sent a
              message to {{ email }} with a verification code, please enter your verification code here.
            </p>
            <Form
              :validation-schema="otp_schema"
              @submit="handleLogin"
            >
              <div class="my-3">
                <div
                  class="form-floating mx-auto"
                  style="width:10rem"
                >
                  <Field
                    v-model="pin_code"
                    maxlength="4"
                    name="pin_code"
                    type="text"
                    placeholder="12TWA34"
                    class="form-control mb-3 mx-auto"
                    autocomplete="off"
                  />
                  <label class="mx-auto">verification code</label>
                </div>
                <div class="text-center">
                  <ErrorMessage
                    name="pin_code"
                    class="error-feedback text-danger"
                  />
                </div>
              </div>
              <div>
                <button
                  class="btn-wts btn btn-primary"
                  :disabled="loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  VERIFY
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Form>
    <div
      v-if="message"
      class="alert alert-danger mt-4"
      role="alert"
    >
      {{ message }}
    </div>
    <p class="subscribe-text text-center pt-4">
      When you subscribe, you support Deaf people from all walks of life.
    </p>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import { Form, Field, ErrorMessage } from "vee-validate";
import { trackAuthentication } from '@/helpers/google-analytics-tracker';
import { createSignedUrl } from "@/helpers/sprout-video";
import * as yup from "yup";

const otp_schema = yup.object().shape({
  pin_code: yup
    .string()
    .required("One-time code is required")
    .min(4, "Enter 4 digits please"),
});

const email_schema = yup.object().shape({
  email: yup.string().required("An email address is required").email('Invalid email address')
});

export default {
  name: "IosNew",
  components: {
    VideoPlayer,
    Form,
    Field,
    ErrorMessage,
  },
  data() {

    return {
      email_schema,
      otp_schema,
      message: "",
      email: "",
      pin_code: "",
      loading: false,
      showOtc: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        loop: true,
        fluid: true,
        playsinline: true,
        muted: true,
        sources: [
          {
            src: this.createSignedUrl(),
            type: 'video/mp4',
          },
        ],
      },

    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSubscribed() {
      return this.$store.state.auth.user.is_subscribed;
    },
  },
  methods: {
    sendCode(form) {
      this.$store.dispatch("auth/sendCode", form).then(
        () => {
          this.email = form.email;
          this.showOtc = true;
        },
        (error) => {
          this.email = "";
          this.showOtc = true;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );

    },
    goToSubscribe() {
      if (this.loggedIn) {
        console.log("user is logged in, going to subscribe page");
        this.$router.push("/subscribe");
      }
    },

    async handleLogin(form) {
      this.message = "";
      this.loading = true;
      form["email"] = this.email;
      this.$store.dispatch("auth/login", form).then(
        () => {
          const { isSubscribed } = this;
          const redirectPath = localStorage.getItem('redirectPath');
          localStorage.removeItem('redirectPath');
          trackAuthentication({ email: this.email, action: 'login:success' });
          if (redirectPath) {
            this.$router.push(redirectPath);
          } else if (isSubscribed) {
            this.$router.push("/search");
          } else {
            this.$router.push("/subscribe");
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          trackAuthentication({ action: 'login:failure', email: this.email });
        }
      );
    },
    createSignedUrl() {
      return createSignedUrl("0690d5b31916efc28f", "fdbf794609f2b853");
    },
  }
};
</script>

<style lang="css" scoped src="../../public/wts.css" />

<style scoped>
.btn-wts {
  width: 100%;
}
</style>
