import { createStore } from "vuex";
import { auth } from "./auth.module";

const store = createStore({
  modules: {
    auth,
  },
  state: {
    version: VERSION,
    successAlertMsg: "",
    errorAlertMsg: "",
  },
  getters: {
    version (state) {
      return state.version;
    },
    successAlertMsg (state){
      return state.successAlertMsg;
    },
    errorAlertMsg (state){
      return state.errorAlertMsg;
    }
  },

  mutations: {
    setVersion (state, version) {
      state.version = version;
      console.log("version updated to: ", state.version);
    },
    setSuccessAlertMsg (state, successAlertMsg) {
      state.successAlertMsg = successAlertMsg;
    },
    setErrorAlertMsg (state, errorAlertMsg) {
      state.errorAlertMsg = errorAlertMsg;
    }
  }

});

export default store;
