// NOTE: (2023-11-13) We currently use Sprouts Video, which uses iframe and
// requires us to pass in parameters to configure videos.
//
// Ref: https://sproutvideo.com/help/articles/61-embed_code_parameters
export default function setUrlParams(content, params) {
  const htmldoc = new DOMParser().parseFromString(content, 'text/html');
  const videoFrames = htmldoc.querySelectorAll('iframe');
  videoFrames.forEach((vf) => {
    // For each video iframe, create a URL object from src and set params and
    // replace the old src with the new
    const url = new URL(vf.getAttribute('src'), window.location);
    for (const entry of Object.entries(params)) {
      url.searchParams.set(entry[0], entry[1]);
      vf.setAttribute('src', url.toString());
    }
  })

  return htmldoc.body.innerHTML;
}
