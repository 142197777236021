<template>
  <div>
    <h2 class="border-bottom">
      Profile
    </h2>
    <div v-if="!userDeleted && currentUser">
      <p>Email: {{ currentUser.email }}</p>
      <button
        class="btn btn-primary btn-sm"
        @click="logOut()"
      >
        <font-awesome-icon icon="sign-out-alt" /> Log Out
      </button>
      <button
        class="btn btn-primary btn-sm mx-3"
        @click="redirect()"
      >
        Back to Main page
      </button>
    </div>
    <div v-else>
      <h3>Your account was deleted.</h3>
      <button
        class="btn btn-primary btn-sm"
        @click="redirect()"
      >
        Back to Login In page
      </button>
    </div>
    <h2 class="mt-3">
      Billing
    </h2>
    <div v-if="!userDeleted && currentUser">
      <div v-if="!subscriptionDetails && subscriptionError">
        <p>{{ subscriptionError }}</p>
      </div>
      <div v-if="subscriptionDetails && subscriptionDetails.subscription">
        <div class="card">
          <ul class="list-group list-group-flush">
            <div
              v-if="subscriptionDetails.subscription.status != 'canceled' && subscriptionDetails.subscription.status != 'trailing'"
            >
              <li class="list-group-item">
                <strong>Plan:</strong> {{
                  humanizeInterval(subscriptionDetails.subscription.plan.interval) }}
              </li>
              <div v-if="!subscriptionDetails.subscription.cancel_at_period_end">
                <li class="list-group-item">
                  <strong>Amount:</strong> {{
                    formatPrice(subscriptionDetails.subscription.plan.amount) }}
                </li>
              </div>
              <li class="list-group-item">
                <div v-if="subscriptionDetails.subscription.cancel_at_period_end">
                  <strong>Next Payment:
                  </strong>
                  You've canceled your subscription.
                </div>
                <div v-else>
                  <strong>Next Payment:
                  </strong>
                  {{
                    formatDate(subscriptionDetails.subscription.current_period_end) }}
                </div>
              </li>
              <li class="list-group-item">
                <div v-if="!subscriptionDetails.subscription.cancel_at_period_end">
                  <strong>Subscription Status: </strong> {{
                    capitalizeFirstChar(subscriptionDetails.subscription.status) }}
                </div>
                <div v-else>
                  <strong>Subscription Status: </strong> {{
                    capitalizeFirstChar(subscriptionDetails.subscription.status) }}
                  until {{
                    formatDate(subscriptionDetails.subscription.current_period_end) }}.
                </div>
              </li>
            </div>
            <div v-if="subscriptionDetails.subscription.status == 'trialing'">
              <li class="list-group-item">
                <div v-if="subscriptionDetails.subscription.cancel_at_period_end">
                  <strong>Trial ends: </strong> {{
                    formatDate(subscriptionDetails.subscription.current_period_end) }}.
                  You will not be billed, because you canceled the subscription renewal already.
                </div>
                <div v-else>
                  <strong>Trial ends: </strong> {{
                    formatDate(subscriptionDetails.subscription.current_period_end) }}.
                  You will be billed at that time.
                </div>
              </li>
            </div>
            <div v-if="subscriptionDetails.subscription.status == 'canceled'">
              <li class="list-group-item">
                <strong>Subscription Status: </strong> {{
                  capitalizeFirstChar(subscriptionDetails.subscription.status) }}
              </li>
              <li class="list-group-item">
                <strong>Date of cancellation:</strong>  {{
                  formatDate(subscriptionDetails.subscription.canceled_at) }}
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div
        v-if="isSubscribed && subscriptionDetails && (!subscriptionDetails.subscription.cancel_at_period_end && subscriptionDetails.subscription.status !== 'canceled')"
        class="row my-3"
      >
        <div class="col">
          <button
            class="btn btn-secondary btn-sm"
            @click="showSubscriptionModal()"
          >
            Cancel Subscription
          </button>
        </div>
      </div>
      <div
        v-if="subscriptionDetails && subscriptionDetails.subscription"
        class="row my-3"
      >
        <div class="col">
          <a
            class="btn btn-secondary btn-sm"
            :href="`${stripePortalLink}?prefilled_email=${encodeURIComponent(
              currentUser.email
            )}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            Manage Billing in Stripe Portal
          </a>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>Your subscription was cancelled.</h3>
    </div>


    <h2 class="text-danger border-bottom mt-3">
      Account
    </h2>
    <div v-if="!userDeleted && currentUser">
      <p>
        Once you delete your account, there is no going back. Please be certain that you want to remove all your
        account data.
      </p>
      <button
        class="btn btn-outline-secondary btn-sm text-danger fw-bold"
        @click="showDeleteModal()"
      >
        Delete Account
      </button>
    </div>
    <div v-else>
      <h3>Your account was deleted.</h3>
    </div>

    <!-- Delete Account Modal -->
    <div
      id="confirmDeletionModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteConfirmationLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered "
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="deleteConfirmationLabel"
              class="modal-title"
            >
              Now just a minute.
            </h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete your whole account?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Nevermind
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteAccount()"
            >
              Delete everything
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Cancel Subscription Renewal Modal -->
    <div
      id="cancelSubscriptionModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="cancelSubscriptionLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="cancelSubscriptionLabel"
              class="modal-title"
            >
              Now just a minute.
            </h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to cancel your subscription?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Nevermind
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="cancelSubscriptionRenewal()"
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import { trackError, trackAuthentication } from '@/helpers/google-analytics-tracker';
import { Modal } from 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

export default {
  name: 'Account',
  data() {
    return {
      userDeleted: false,
      deleteConfirmation: undefined,
      cancelSubscriptionModal: undefined,
      subscriptionDetails: "",
      subscriptionError: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSubscribed() {
      return this.$store.state.auth.user.is_subscribed;
    },
    stripePortalLink() {
      return process.env.VUE_APP_STRIPE_PORTAL_LINK;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.deleteConfirmation = new Modal(document.getElementById('confirmDeletionModal'));
    this.cancelSubscriptionModal = new Modal(document.getElementById('cancelSubscriptionModal'));
    this.getSubscription();

  },
  methods: {
    showDeleteModal() {
      this.deleteConfirmation.show();
    },
    deleteAccount() {
      this.deleteConfirmation.hide();
      if (this.currentUser) {
        UserService.deleteAccount().then(
          () => {
            this.userDeleted = true;
            this.$store.commit('setSuccessAlertMsg', "Your subscription was cancelled, and your account was deleted.");
            trackAuthentication({ user: this.$store.state.auth.user, action: 'account:deleteAccount' });
            trackAuthentication({ user: this.$store.state.auth.user, action: 'logout' });
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          },
          (error) => {
            this.$store.commit('setErrorAlertMsg', "There was an error with account deletion, please try again later.");
            trackError(error);
          }
        );
      }
    },
    redirect() {
      if (this.currentUser && this.isSubscribed && this.loggedIn) {
        this.$router.push('/search');
      }
      else {
        this.$router.push('/login');
      }
    },
    cancelSubscriptionRenewal() {
      this.cancelSubscriptionModal.hide();
      if (this.currentUser) {
        UserService.cancelSubscriptionRenewal().then(
          () => {
            this.$store.commit('setSuccessAlertMsg', "You cancelled your subscription renewal. You can keep using What's The Sign?™ until the end of the billing cycle.");
            trackAuthentication({ user: this.$store.state.auth.user, action: 'account:cancelSubscriptionRenewal' });
            this.getSubscription();
          },
          (error) => {
            this.$store.commit('setErrorAlertMsg', "There was an error with cancelling your subscription renewal, please try again later.");
            trackError(error);
          }
        );
      }
    },
    formatPrice(value) {
      if (typeof value !== "number") {
        return value;
      }

      value = value / 100;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },

    formatDate(value) {
      var date = new Date(value * 1000);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options)
    },

    humanizeInterval(word) {
      const capitalizedFirst = word[0].toUpperCase();
      const rest = word.slice(1);
      return capitalizedFirst + rest + 'ly';
    },

    capitalizeFirstChar(word) {
      const capitalizedFirst = word[0].toUpperCase();
      const rest = word.slice(1);
      return capitalizedFirst + rest;
    },

    getSubscription() {
      UserService.getSubscriptionDetails().then(
        (response) => {
          this.subscriptionDetails = response.data;
        },
        (error) => {
          if (this.$store.state.auth.user.is_subscribed) {
            this.subscriptionError = "We could not retrieve your subscription information, please try again later."
          }
          else {
            this.subscriptionError = "We could not get your subscription information, it is likely that you have not signed up for a subscription yet."
          }
          trackError(error);
        }
      );
    },
    async logOut() {
      trackAuthentication({ user: this.$store.state.auth.user, action: 'logout' })
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    showSubscriptionModal() {
      this.cancelSubscriptionModal.show();
    },
  }
};
</script>
