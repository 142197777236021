import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL

export class SignService {
  search(term) {
    if (term.startsWith('unit ')) {
      const searchUrl = `${API_URL}/searches/unit`
      return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
    }
    else {
      const searchUrl = `${API_URL}/searches`
      return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
    }
  }
  searchFollowedBy(term) {
    const searchUrl = `${API_URL}/searches/followed_by`
    return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
  }
  searchMulticolumn(term) {
    const searchUrl = `${API_URL}/searches/multicolumn`
    return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
  }
  searchRelatedTags(term) {
    const searchUrl = `${API_URL}/searches/related_tags`
    return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
  }
  getSuggestedTerms(term) {
    const searchUrl = `${API_URL}/searches/suggested_terms`
    return axios.get(`${searchUrl}?term=${term}`, { headers: authHeader() });
  }
}

export default new SignService();
