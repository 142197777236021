<template>
  <div>
    <div class="row">
      <div class="col">
        <ThanksForSubscribing
          v-if="sessionId"
          :given-session-id="sessionId"
        />
        <Form
          id="form-term-search"
          :validation-schema="schema"
          @submit="findSigns(search)"
        >
          <div class="input-group">
            <Field
              id="search_input"
              v-model="search"
              name="search"
              type="text"
              class="form-control"
              autocomplete="off"
              list="suggested-terms"
            />
            <div v-if="suggestedTermsEnabled">
              <datalist
                id="suggested-terms"
              >
                <option
                  v-for="term in suggestedTerms"
                  :key="term.id"
                  :value="term.title"
                >
                  {{ term.title }}
                </option>
              </datalist>
            </div>
            <button
              class="btn btn-primary"
              :disabled="isSearchDisabled"
            >
              <span
                v-show="loading && !autoupdateResultsEnabled"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Search
            </button>
          </div>
          <ErrorMessage
            name="search"
            class="error-feedback"
          />
        </Form>
        <div
          v-if="tsvSearchEnabled"
          class="mt-3"
        >
          <div class="form-check form-check-inline">
            <input
              id="search-option-a"
              v-model="searchOption"
              class="form-check-input"
              type="radio"
              value="A"
            >
            <label
              class="form-check-label"
              for="search-option-a"
            >Current</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="search-option-b"
              v-model="searchOption"
              class="form-check-input"
              type="radio"
              value="B"
            >
            <label
              class="form-check-label"
              for="search-option-b"
            >
              Followed By
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="loading && autoupdateResultsEnabled"
        class="row"
      >
        <div class="pt-4 my-1">
          Looking …
        </div>
        <div class="col">
          <div class="list-group">
            <div
              v-for="i in 6"
              :key="i"
              class="list-group-item placeholder-glow"
            >
              <span
                class="placeholder"
                :class="`col-${i % 3 + 1}`"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!loading"
        class="row"
      >
        <div
          v-if="terms.length"
          class="pt-4 my-1"
        >
          {{ terms.length }} results
        </div>
        <div class="col">
          <div class="list-group">
            <!-- eslint-disable vue/no-v-html -->
            <a
              v-for="(term, index) in terms"
              :key="term.id"
              href="#"
              class="list-group-item list-group-item-action"
              @click.prevent="showSigns(index)"
              v-html="term.title"
            />
          <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>

        <div
          v-if="tagsWithTerms.length"
          class="pt-4 my-1"
        >
          We found {{ tagsWithTerms.length }} related tags
        </div>
        <div
          id="related-tags-container"
          class="accordion"
          style="cursor: pointer;"
        >
          <div
            v-for="item in tagsWithTerms"
            :key="item.tag.id"
            class="accordion-item"
          >
            <h2
              :id="`tag-heading-${item.tag.id}`"
              class="accordion-header"
            >
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#related-tag-${item.tag.id}`"
                aria-expanded="true"
                :aria-controls="`related-tag-${item.tag.id}`"
              >
                {{ item.tag.name }} ({{ item.terms.length }} entries)
              </button>
            </h2>
            <div
              :id="`related-tag-${item.tag.id}`"
              class="accordion-collapse collapse"
              data-bs-parent="#related-tags-container"
              :aria-labelledby="`tag-heading-${item.tag.id}`"
            >
              <div class="accordion-body p-0">
                <div class="list-group list-group-flush">
                  <a
                    v-for="term in item.terms"
                    :key="term.id"
                    href="#"
                    class="list-group-item list-group-item-action"
                    @click.prevent="showSigns(collectedTerms.findIndex(t => t.id === term.id))"
                  >
                    {{ term.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!collectedTerms.length && searchCompleted && search"
          class="pt-4"
        >
          <div class="col">
            <p>No results found for <b>"{{ search }}"</b></p>
            <p>Try these tips:</p>
            <ul>
              <li>Check your spelling</li>
              <li>Add or remove plural endings: -s, -es, -ies, etc.</li>
              <li>Add spacing or remove spaces between words/parts of a word</li>
            </ul>
            Or maybe that word doesn't exist in the dictionary yet.
            Email hello@whatsthesign.com your suggestion!
          </div>
        </div>

        <!-- if search is empty, show themes -->
        <div
          v-if="!search"
          class="row pt-4"
        >
          <h5
            class="col fw-bolder"
            style="color: rgb(94, 43, 129);"
          >
            What's The Sign?™ Themes
          </h5>
        </div>

        <div
          v-if="!search"
          id="accordion"
          class="pt-4 pb-0"
        >
          <div class="">
            <div
              v-for="unit in units"
              :key="unit.name"
              class="card mb-3"
            >
              <div
                class="card-header"
                style="cursor: pointer;"
                data-bs-toggle="collapse"
                :data-bs-target="'#unit_' + unit.id"
              >
                {{ unit.title }}
              </div>
              <ul
                :id="'unit_' + unit.id"
                class="list-group list-group-flush collapse"
                data-bs-parent="#accordion"
              >
                <button
                  v-for="glance in unit.glances"
                  :key="glance.name"
                  type="button"
                  class="list-group-item list-group-item-action"
                  @click="findSigns('unit ' + glance.id)"
                >
                  {{ glance.name }}
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        id="moon"
        class="modal"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <!-- eslint-disable vue/no-v-html -->
              <h5
                class="modal-title"
                v-html="selected_term.title"
              />
              <!-- eslint-enable vue/no-v-html -->
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="selected-term-content"
                v-html="selected_term.content"
              />
              <!-- eslint-enable vue/no-v-html -->

              <!-- <div class="form-check form-switch"> -->
              <!--   <input -->
              <!--     type="checkbox" -->
              <!--     v-model="loop" -->
              <!--     role="switch" -->
              <!--     id="video-loop-toggle" -->
              <!--     class="form-check-input" -->
              <!--     @click="toggleLoop" -->
              <!--   > -->
              <!--   <label -->
              <!--     for="video-loop-toggle" -->
              <!--     class="form-check-label" -->
              <!--   > -->
              <!--     Toggle video loop -->
              <!--   </label> -->
              <!-- </div> -->

              <div class="wts-btn-nav">
                <div class="wts-btn-prev">
                  <button
                    v-if="selected_index > 0"
                    class="btn btn-outline-secondary btn-previous"
                    @click="prevSigns()"
                  >
                    Back
                  </button>
                </div>

                <div class="wts-btn-next">
                  <button
                    v-if="selected_index < collectedTerms.length - 1"
                    class="btn btn-outline-secondary btn-next"
                    @click="nextSigns()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import SignService from "../services/sign.service";
import { Modal } from 'bootstrap'
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { track, trackAuthentication, trackError } from '../helpers/google-analytics-tracker';
import setUrlParams from '../helpers/set-url-params';
import ThanksForSubscribing from "./ThanksForSubscribing.vue";
import FeatureService from '@/services/feature.service';
import debounce from "@/helpers/debounce";

export default {
  name: 'Sign',
  components: {
    Form,
    Field,
    ErrorMessage,
    ThanksForSubscribing,
  },
  data() {
    const schema = yup.object().shape({
      search: yup.string(),
    });

    return {
      loading: false,
      moon: undefined,
      search: ref(""),
      searchCompleted: false,
      terms: [],
      relatedTags: [],
      selected_index: 0,
      selected_term: {
        title: '',
        content: '',
      },
      schema,
      sessionId: null,
      suggestedTerms: [],
      loop: localStorage.getItem('videoloop') || false,
      relatedTagsEnabled: false,
      suggestedTermsEnabled: false,
      autoupdateResultsEnabled: false,
      searchOption: "A",
      tsvSearchEnabled: false,
      playsinlineEnabled: false,
      units: [
        {
          id: '1',
          name: 'Unit 1',
          title: 'Welcome to the Deaf World!',
          link_1: 'https://drive.google.com/drive/folders/1xxZMeOckzVRd-qpY_fIFKhbe77VA3cga?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/186HLUEu_jrab4pxmv7bFqqr976FdqaW2?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1Hbu0HQuEvYfx09650L2Skvk2fxhzbyo5?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1ZXTxXu8i4EL-YLVgX9KbBFmhgpE8fNd4?usp=sharing',
          glances: [
            {
              id: '1.1',
              name: 'Getting Started',
              content: '<p>Snapshots:</p><ul><li>Immersion</li><li>Different Learning Styles</li><li>Seating Arrangements</li><li>Attention Getting</li><li>Which Hand?</li><li>Signing Sightlines</li><li>The Signing Space</li><li>Backchanneling</li><li>Where Do I Look?</li></ul><p>PP Content:</p><ul><li>First Few Signs (yes, no, practice, immersion, cool/neat, see you later)</li><li>Activity: Attention-Getting Signals</li><li>Activity: Elephant</li></ul>',
              note: null
            },
            {
              id: '1.2',
              name: 'Introduction to ASL',
              content: '<p>Snapshots:</p><ul><li>Common Misconceptions</li><li>Braille</li><li>Letters, Characters, and Handshapes</li><li>Basic Grammar Structure&nbsp;</li><li>Faces are Important Too</li><li>Navigating TWA</li><li>Sign Parameters</li></ul><p>PP Content:</p><ul><li>Basic Signs: different, same, remember, forget</li><li>Activity: Getting to Know Your Peers</li><li>Activity: Identify the Differences (parameters)</li></ul>',
              note: null
            },
            {
              id: '1.3',
              name: 'ASL Foundations',
              content: '<p>Snapshots:</p><ul><li>Why is Everyone Signing So Fast?</li><li>Fingerspelling Tips</li><li>Learning the Alphabet</li><li>Common Sentence Types</li><li>Fingerspelling Names</li><li>Pronouns and Possessive Pronouns</li><li>HER</li><li>DeafDisabled</li></ul><p>PP Content:</p><ul><li>The ASL Alphabet</li><li>Spell this</li><li>Sign Substitute</li><li>ASL Activity: Fingerspelling</li><li>Review: Wh-Q Signs (what, who, where, when, why, which, how)</li><li>YOUR NAME WHAT?</li><li>ASL Activity: Identify Statement Type</li><li>Pronouns (index finger): I, you, she/he/they(singular), we/us, they/them (plural), it</li><li>Possessive Pronouns (open “B”): my, your, hers/his/their(singular), our/ours, their/theirs(plural), its</li><li>HER vs HER</li><li>Plural Pronouns (two-of-us, two-of-them… up to 9, a group of us, a group of them</li><li>Deaf Status (DeafBlind,&nbsp;DeafDisabled*, Deaf, Hard of Hearing, Late-deafened, hearing)</li><li>Time of the Day: morning, noon, afternoon, night</li><li>Greetings: Hello, Good morning….How are you? What’s happening, etc</li><li>Feeling/Mood: Good, Fine, Lousy, Tired, etc</li><li>Farewells: Bye, good night, see you later</li><li>Day of the Week: Monday-Sunday, See you on…, Assignment’s due on…</li><li>Inductions: Hello, my name is…, nice meeting you, I’d like to introduce…</li><li>Manners: Excuse me, May I ask a question?, Thank you-You’re welcome</li><li>Clarifications: I don’t understand, Can you please repeat that?</li><li>Conversation Starter: Guided Dialogue</li><li>More Basic Signs: finish, repeat, online, computer, context, concept, translate, if, video recording, homework, study, do, please, sorry</li><li>Opposites: Want – don’t want, like – don’t like, know – don’t know, understand – don’t understand, correct – incorrect, now – later</li></ul>',
              note: '*DeafDisabled is a relatively new term created by Meredith Burke, a DeafDisabled woman' + ' with Cerebral Palsy. DeafDisabled essentially means Deaf people' + ' with Disabilities, however, DeafDisabled people want to be explicit about the equal' + ' importance of their intersectional identities as a Disabled person as a Deaf person,' + ' hence the merge of both words into one word:  DeafDisabled.  This is similar to DeafBlind' + ' communities’ desire to be viewed as DeafBlind, and not as a Deaf person with blindness.' + ' Please read Meredith Burke’s MA thesis (Meredith Burke’s MA Thesis) to learn more about' + ' the DeafDisabled term which includes all Deaf people with disabilities, whether the' + ' disability is visible (cerebral palsy, amputee, wheelchair user) or invisible (e.g.' + ' diabetes, ADHD, dyslexia).<br/>Likewise, to learn more about DeafBlind and Protactile' + ' language and philosophy, please review: http://www.protactile.org/'
            },
            {
              id: '1.4',
              name: 'Classroom Actions',
              content: '<p>Snapshots:</p><ul><li>Articles (a/an &amp; the)</li><li>Noun Then Verb</li></ul><p>PP Content:</p><ul><li>Noun Then Verb</li><li>Examples of Noun then Verb: BOOK YOUR OPEN…, PENCIL IX PICK-UP…, TEACHER IX DISCUSS, WATCH/LOOK-AT, STAND-UP, SIT-DOWN, WRITE-ON…, ANSWER, DRAW</li><li>Working with your classmates: discuss a problem, brainstorming, work in a group, help</li><li>Working with a partner: Ask, share, dictate</li></ul>',
              note: null
            },
            {
              id: '1.5',
              name: 'Basic Math and Numbers',
              content: '<p>Snapshots:</p><ul><li>Cardinal and Quantity</li></ul><p>PP Content:</p><ul><li>Cardinal/Quantity Numbers</li><li>Numbers: 0-66</li><li>Numbers: Tens</li><li>Numbers: Twin Double Digits</li><li>Basic Math: Addition, Subtraction, Multiplication, Divide, and Answer</li><li>More Math: How many? Altogether, Figure out, Problem</li></ul>',
              note: null
            }
          ]
        },
        {
          id: '2',
          name: 'Unit 2',
          title: 'Academics',
          link_1: 'https://drive.google.com/drive/folders/1lghC55rHqpxTfuEUClzgGxg7aCZf2-i-?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1PdaQSRUOLK6yhDoVsmkF7VDyveYR3YO5?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1GLGhApVKcD2SwSQ4C2ZeAnaF8IUDA1oh?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1MIGQBQhdLGhWT6yqMCUrAEG14F7DpRwT?usp=sharing',
          glances: [
            {
              id: '2.1',
              name: 'Types of Schools',
              content: '<p>Snapshots:</p><ul><li>Names with Prefixes</li><li>Names with Apostrophes</li><li>Ordinal Numbers</li><li>Higher Education Institutions</li></ul><p>PP Content:</p><ul><li>Schools: Nursery to preschool to high school, vocational school, ABE</li><li>School Types: Public, charter, parochial, private, boarding, home</li><li>School Choices for Deaf Students: Charter, Residential School, Regional Day School, public, mainstream, self-contained classrooms</li><li>Grade level (ordinal): 1st grade – 12th grade</li><li>Class Status: Preparatory to Senior</li><li>Postsecondary Education: Community college, university, graduated, enroll, law school, medical school</li><li>Review: College (common mistake – handshape: C)</li></ul>',
              note: null
            },
            {
              id: '2.2',
              name: 'School Environment',
              content: '<p>Snapshot:</p><ul><li>Names with Titles</li></ul><p>PP Content:</p><ul><li>School environment: Campus, playground, different labs, different offices, campus police, library, cafeteria, auditorium, gym, registrar’s office, classroom, etc</li><li>Things in a Classroom: Whiteboard, door, window, desk, projector, etc.</li><li>School Supplies: Notebook, pencil, pen, stapler, calculator, etc</li></ul>',
              note: null
            },
            {
              id: '2.3',
              name: 'Things You do in the Classroom',
              content: '<p>Snapshots:</p><ul><li>BREAK</li><li>Noun-Verb Pairs</li><li>CIRCLE and CIRCLE</li></ul><p>PP Content:</p><ul><li>School-Related Actions: Check, label, unscramble, compare, fill in the blank, multiple-choice, etc</li><li>”Circle”. Draw vs Shape</li><li>Manipulate the Signs: ”take out”, ”put…”, lift, carry, give, turn on/off, run, walk, eat, etc</li><li>Noun and Verb Pairs</li></ul>',
              note: null
            },
            {
              id: '2.4',
              name: 'School Personnel and Colors',
              content: '<p>Snapshots:</p><ul><li>Agent Markers</li><li>Who is Your Teacher?</li><li>Dark, Light, and Bright Colors</li></ul><p>PP Content:</p><ul><li>School Personnel: Counselor, teacher, principal, security officer, coach, librarian, janitor, president, coordinator, etc</li><li>Colors: RYOGBV, tan, brown, black, grey, white</li><li>Different shades of colors</li><li>Neon/Bright colors</li></ul>',
              note: null
            },
            {
              id: '2.5',
              name: 'School Subjects, Majors and Courses',
              content: '<p>Snapshots:</p><ul><li>Academic Degrees</li><li>Conjunctions - And & Then</li><li>Numbers 67-98</li><li>Roman Numerals</li></ul><p>PP Content:</p><ul><li>School Subjects/Courses: Math to Science to Culinary Arts, Languages</li><li>Area of Study, Majors, and Minors: Psychology, Business, Medical, ASL Studies, Engineering, Theatre, etc</li><li>Diploma/Degrees: High school, GED, AA, BA, MA, PhD, etc</li><li>To sign or not to sign Doctor (#DR vs DOCTOR)</li><li>College status: Drop out, on hold, withdraw, full—time, part-time, transfer, semester, graduated</li><li>Numbers: 67-98 (movements)</li><li>More numbers: 100, 1000, 10000, 100000, 1 million</li><li>The zero in hundreds</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '3',
          name: 'Unit 3',
          title: 'Family and Relationships',
          link_1: 'https://drive.google.com/drive/folders/1PqvXhus1y0acC50y7HNpmh8yXbr8m8KO?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1jzK0Ii2alLfRbXzNllyEByh243clwlCg?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1y4OixfdQzPc07mcvnCBCNMs742GxwrLE?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1E6tm533DpcdRJn9buKMW7ywv-5JoW4_J?usp=sharing',
          glances: [
            {
              id: '3.1',
              name: 'Describing Basic Appearances',
              content: '<p>Snapshots:</p><ul><li>Referents</li><li>Referents - Present and Not Present</li><li>Listing and Ranking</li></ul><p>PP Content:</p><ul><li>Adult, child/children</li><li>Height: Tall, average, short</li><li>Use of Non-Manual Markers in ASL</li><li>Compliments: Handsome, So far, look different, resemblance, beautiful, cute, no change, look great</li></ul>',
              note: null
            },
            {
              id: '3.2',
              name: 'Family, Friends, and Pets',
              content: '<p>Snapshots:</p><ul><li>Ranking Siblings</li><li>Gender Signs</li><li>Compound Signs</li><li>NONE and NO</li><li>CODAs and More</li><li>Ages</li><li>Deaf Dogs</li><li>Name Signs</li><li>WEDDING and MARRIED</li></ul><p>PP Content:</p><ul><li>Relationship status: Fall in love, dating, affair, break up, single, wedding, etc</li><li>Marry vs Wedding (common mistake)</li><li>Family members: grandparents, parents, siblings, cousin, aunt, uncle, step-, half-, adopted, in-laws, daughter, son, niece, nephew, etc</li><li>Babies to Seniors: pregnant, weight (at birth), babies, children, teenagers, adults, etc</li><li>Multiple Birth: Identical, Fraternal twins</li><li>None vs No</li><li>Pets: cat, dog, rabbit, fish, snake, chicken, etc</li><li>What do you like to do with your pets? (feed, play, exercise, talk with, etc)</li><li>Circle of Friends (best friends, close friends, colleagues, acquaintances, Ex)</li></ul>',
              note: null
            },
            {
              id: '3.3',
              name: 'Employment Status',
              content: '<p>Snapshots:</p><ul><li>FULL and ENOUGH</li></ul><p>PP Content:</p><ul><li>Employment Status: Working, internship, self-employed, promote, retired, laid off, jobless, quit, fired, etc</li></ul>',
              note: null
            },
            {
              id: '3.4',
              name: 'Milestones and Life Events',
              content: '<p>Snapshots:</p><ul><li>HAVE and HAVE-TO</li></ul><p>PP Content:</p><ul><li>HAVE-TO and HAVE</li><li>HAVE-TO, MUST, SHOULD, and NEED (sign, movement &amp; mouth morpheme)</li><li>Signs: Transitions (Later, finish/then, enter, start, year+, move-there, etc)</li><li>Year+(number): specific, era, year range, years</li><li>Life Major Events: born, school years, first car, first job, relationship, family, retired, etc</li><li>Important Documents: Birth Certificate, Driver’s license, Social Security, Passport, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '4',
          name: 'Unit 4',
          title: 'Residences and Communities',
          link_1: 'https://drive.google.com/drive/folders/1iMdkcsIE5otk-eHj853Uskb5lm9Jd4Ls?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/19dQGchhXmPuWnAkohdlZqpJxEEye7gMF?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1LnC8A6xcsKAkpRxHGtobUpU-Noh1zAvt?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1qUcu220yAQI79yaWRUGfwhrCC_yeJ0hf?usp=sharing',
          glances: [
            {
              id: '4.1',
              name: 'Transportation',
              content: '<p>Snapshots:</p><ul><li>TAKING-OFF</li><li>Lexicalized Fingerspelling</li></ul><p>PP Content:</p><ul><li>Energy: Gas, electric, hybrid</li><li>Types of Transportation: Car, van, truck, 18-wheeler, motorcycle, horse, train, subway, boat, walk, etc</li><li>Which handshape: Riding (handshapes 3 &amp; B)</li><li>Review: Noun-Verb Pairs: Car, Airplane</li><li>More Relevant Vocabulary: place, here, there, drop off, pick up, leave, gas-guzzler, commute, HOV, etc</li></ul>',
              note: null
            },
            {
              id: '4.2',
              name: 'Housing and Dwellings',
              content: '<p>Snapshots:</p><ul><li>AND</li><li>THERE</li></ul><p>PP Content:</p><ul><li>Types of Housings: Apartment, townhouse, house, dorm, shelter, farm, houseboat, etc</li><li>Living Arrangements: live with, significant other, family, roommate(s), pets, live alone</li></ul>',
              note: null
            },
            {
              id: '4.3',
              name: 'Places Around Town',
              content: '<p>Snapshots:</p><ul><li>Deaf Owned Businesses</li></ul><p>PP Content:</p><ul><li>Places: school, gym, city hall, park, library, police station, hospital, post office, bank, shopping mall, restaurant, hotel, Mosque, Synagogue, Temple, Church, downtown, neighborhood, etc</li></ul>',
              note: null
            },
            {
              id: '4.4',
              name: 'States, Provinces, and Cities',
              content: '<p>Snapshots:</p><ul><li>Deaf Hubs</li><li>ASL in United States and Canada</li></ul><p>PP Content:</p><ul><li>Compass Rose: North, South, West, East, Northwest, Southeast, etc</li><li>United States of America: 50 states, Federal District &amp; 5 Territories</li><li>Canada: 10 Provinces &amp; 3 Territories</li><li>Major cities in US &amp; Canada</li><li>Your State/Province Neighborhoods*</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '5',
          name: 'Unit 5',
          title: 'Scheduling',
          link_1: 'https://drive.google.com/drive/folders/1Fe_u24XzotKnltWbLq6y9r4WpWKwnq3C?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1MI4Jz4TEuBUF_7dk_DARVPNxa7v_wVeb?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1wpHAsbsGdzzPqL3zyNOBo8AgZbyyZzOg?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1s_426QiMNp6DVXycDTEl_IGcpDDK9W7D?usp=sharing',
          glances: [
            {
              id: '5.1',
              name: 'Calendar & Time Expressions',
              content: '<p>Snapshots:</p><ul><li>Numeral Incorporation</li><li>YEAR</li><li>Time and Non-Manual Markers</li><li>Ordinal Suffixes</li><li>Eyeth</li><li>Clock Numbers</li><li>Times of the Day</li><li>Telling Time</li><li>Two Degrees of Separation</li></ul><p>PP Content:</p><ul><li>Schedule</li><li>Calendar</li><li>Month: Jan – Dec</li><li>Day: Sunday-Saturday</li><li>Handshapes for Monday, Wednesday, Friday and Saturday (common mistakes)</li><li>Week, Weekend, First-fourth week of the month</li><li>Timeline within the Signing Space: Past, Present, Future</li><li>Numeral Incorporation: 1-9 for minutes, hour, day, week, month &amp; year*</li><li>Day: Yesterday, today, tomorrow,</li><li>Day: 1-9, last/past, future (only up to 3)</li><li>Week:&nbsp; 1-9, last/past, &amp; future (1-9)</li><li>Month: 1-9, last/past, &amp; future (1-9)</li><li>Year:1-5, last/past &amp; future (1-5)</li><li>More Signs: Year (many years, as the years go by, yearlong/year-round)</li><li>Dates: 1st-9th, 10th-31st</li><li>Time-related Non-Manual Markers:&nbsp; CS, MM, AHH</li><li>Moon, Earth, Sun</li><li>Time of the Day: Morning, noon, afternoon, evening, night</li><li>Length: All day, All night</li><li>LAST:&nbsp; last night, last name, last day of, last game</li><li>Clock: time, clock, hour, minute</li><li>Clock Times: 1-9, &nbsp;amount of hours, past &amp; future (1-9)</li><li>Time: 5:05, 11:11, 12:02</li><li>Time duration:&nbsp; from 4:00 to 7:00, 15 minutes, half &nbsp;an hour</li><li>Time: Miscellaneous: About, 24/7, on time, oversleep, next time, early, etc</li></ul>',
              note: null
            },
            {
              id: '5.2',
              name: 'Events',
              content: '<p>Snapshots:</p><ul><li>Regional Variations</li><li>Homecoming</li></ul><p>PP Content:</p><ul><li>Event-Related: Celebrate, national holiday, decorate, symbol, parade, patriotic</li><li>Holidays and Observances: New Year’s Day, MLK, Chinese New Year, Memorial Day, Juneteeth, Canada Day, The Fourth of July, El Grito, International Deaf Day, Halloween, Day of the Dead, Ramadan, etc</li><li>Calendar Events: Birthday, wedding, reunion, homecoming, Expo, Olympics</li><li>Break (semantics): Semester break, Spring break, winter break, (holiday) break, On a break</li></ul>',
              note: null
            },
            {
              id: '5.3',
              name: 'Errands',
              content: '<p>Snapshots:</p><ul><li>CLEAN</li><li>BABYSIT</li></ul><p>PP Content:</p><ul><li>Errands: Appointment/Reservation, cancel, pay bills, drop off, pick up, work, dry cleaning, grocery, doctor, bank, meeting, interview, etc</li><li>“Sitting”: babysitting, house sitting, pet sitting</li><li>Calendar-related: plan, planning, conflict, double-booking, change of plans, postpone, procrastinate, slack off, etc</li></ul>',
              note: null
            },
            {
              id: '5.4',
              name: 'Reasons & Excuses',
              content: '<p>Snapshots:</p><ul><li>Explaining Why Late</li></ul><p>PP Content:</p><ul><li>Arriving late or early</li><li>Reasons &amp; Excuses: Car accident, traffic, pulled over by a cop, ran out of gas, lost keys, overslept, forgot, etc</li><li>Absent: Sick, Skip</li></ul>',
              note: null
            },
            {
              id: '5.5',
              name: 'Frequency',
              content: '<p>Snapshot:</p><ul><li>FREQUENT</li><li>Common Error - FREQUENT</li><li>HAVE-TO, NEED, MUST, and SHOULD</li></ul><p>PP Content:</p><ul><li>How often? (often, sometimes, rarely, never, always, usually, etc)</li><li>Week: everyday, once, twice, three times a week, every Monday/Mondays, every morning, etc</li><li>Numeral incorporation: 1-9 = month, week, hour,&nbsp; 1-5 = year</li></ul>',
              note: null
            },
            {
              id: '5.6',
              name: 'Daily Activities',
              content: '<p>Snapshot:</p><ul><li>STORE and SHOP</li><li>MONEY, BUY, and SHOP</li><li>BED and SLEEP</li><li>FEED and EAT</li></ul><p>PP Content:</p><ul><li>Daily Routines: Get up, shower, brush teeth, shave, apply makeup, go to bed, clean, wash dishes, walk the dog, go to work/school, cook, breakfast, lunch, dinner, etc</li><li>Review: A Day</li></ul>',
              note: null
            }
          ]
        },
        {
          id: '6',
          name: 'Unit 6',
          title: 'Good Times',
          link_1: 'https://drive.google.com/drive/folders/1TIRMOWLLUOAeV-Y_bTkZZ65xiX7rN1Wu?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1vhIUPRkbvK4uP3FR8zdgwUtsjmySySWb?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1jfu_-t4oYwMM8uvCstJZLe63e-6gBSDs?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/10M3FR2Z_DCoRzjshNIxmEoMsqzDVvol5?usp=sharing',
          glances: [
            {
              id: '6.1',
              name: 'Insights and Skills',
              content: '<p>Snapshots:</p><ul><li>Opinions</li><li>Skill Levels</li><li>Bravery</li><li>Lost in Translation</li></ul><p>PP Content:</p><ul><li>Opinions: CHAMP, KISS+FIST, #FUN, FUN, ENJOY, LIKE, OK-OK, SO-SO, DON’T-LIKE, BORING, VOMIT</li><li>What do you think of…</li><li>Activity Skills: #PRO, TERRIBLE, EXPERT, SKILL, GOOD++. SO-SO, CLUMSY. LOUSY, INEPT</li><li>How good are you at…</li><li>Bravery: RUSH, THRILL, BRAVE, RISK, BOLD, STRUGGLE, NERVOUS, CHICKEN, SCARED, CAN’T</li><li>Would you dare…?</li></ul>',
              note: null
            },
            {
              id: '6.2',
              name: 'Hobbies and Interests',
              content: '<p>Snapshots:</p><ul><li>Deaf Gatherings</li></ul></li></ul></li></ul><p>PPT Content:</p><ul><li>Hobbies &amp; Interests: Cooking, baking, playing video games, reading, knitting, etc</li><li>Collections: Coins, figurines, antiques, comics, stamps, etc</li></ul>',
              note: null
            },
            {
              id: '6.3',
              name: 'Sports and Recreation',
              content: '<p>Snapshots:</p><ul><li>VISIT, DRIVE, and TEXT</li><li>LAPS</li><li>Playing with Signs</li><li>Deaflypmics</li></ul><p>PP Content:</p><ul><li>Sports &amp; Recreations: Party, Socialize, playing cards, camping, swimming, snowboarding, hockey, golf, volleyball, etc</li><li>Olympics, Paralympics, Special Olympics, Deaflympics</li></ul>',
              note: null
            },
            {
              id: '6.4',
              name: 'Vacations',
              content: '<p>Snapshots:</p><ul><li>Signing Camps</li></ul><p>PP Content:</p><ul><li>Vacations: Road trip, sightseeing, camping, Carnival/fair, museum, festival, etc</li></ul>',
              note: null
            },
            {
              id: '6.5',
              name: 'Snack Time',
              content: '<p>Snapshot:</p><ul><li>How do you like your coffee?</li></ul><p>PP Content:</p><ul><li>Snacks: Doughnuts, popcorn, cheese, crackers, nuts, veggies, fruits, cookies, ice cream, etc</li><li>Drinks: Water, milk, juice, soda, coffee, tea, etc</li><li>How do you like your coffee/tea? (iced, hot, plain, cream, sugar, etc)</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '7',
          name: 'Unit 7',
          title: 'All About Food!',
          link_1: 'https://drive.google.com/drive/folders/15tNaAs_UinhYM5WtyCr2NE3ZASYkhgTs?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1d5cA3kFeE60RXK3Dg6SxM6MTsDt9Eq4h?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1a9veU_ArWSgzVb46fn_gE_jpWX1IUPiH?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1-eU2T0G43qQKeWtyiZKAt8tYMOrhWnDp?usp=sharing',
          glances: [
            {
              id: '7.1',
              name: 'Fruits and Vegetables',
              content: '<p>Snapshots:</p><ul><li>EGGPLANT</li></ul><p>PP Content:</p><ul><li>Fruits: Apple, orange, lemon, bananas, watermelon, etc</li><li>Vegetables: Lettuce, onion, carrot, mushroom, corn, etc.</li></ul>',
              note: null
            },
            {
              id: '7.2',
              name: 'Meats, Poultry, and Seafood',
              content: '<p>Snapshots:</p><ul><li>Categories and Sub-categories</li></ul><p>PP Content:</p><ul><li>Red Meat: Roast beef, steak, ground beef, lamb</li><li>Poultry: Chicken, turkey, duck, thighs, breasts, drumsticks, wings</li><li>Pork: Pork chop, ham, bacon, ribs, hotdog, sausage, bratwurst, pepperoni</li><li>Seafood: Fish, catfish, shrimp/prawn, lobster, crab</li></ul>',
              note: null
            },
            {
              id: '7.3',
              name: 'More Food and Sweets',
              content: '<p>Snapshots:</p><ul><li>DRINK</li><li>Mouthing Portions</li><li>DEAF COFFEE</li></ul><p>PP Content:</p><ul><li>Dairy Products: Milk, sour cream, cheese, etc</li><li>Packaged Goods: Cereal,&nbsp; pasta, etc</li><li>Canned Goods: Canned fruit, canned vegetables, soup, tuna</li><li>Jams &amp; Jellies: Jam, jelly, peanut butter</li><li>Condiments: Ketchup, mustard, pickles, salsa, soy sauce, honey, etc</li><li>Baking Products: Flour, sugar, eggs, tofu, cornmeal</li><li>Baked Goods: Bread, English muffins, tortilla, etc</li><li>Beverages: Water, soda, diet soda, energy drink, powered drink, protein shake</li><li>Juice: Apple juice, orange juice, lemonade, etc</li><li>Coffee &amp; Tea: Coffee, decaf, instant coffee, tea, hot chocolate, iced tea or coffee</li><li>Liquor: Beer, alcohol, wine</li><li>Sweets: Cake, pie, ice cream, cookies, etc</li></ul>',
              note: null
            },
            {
              id: '7.4',
              name: 'Regional Cuisines',
              content: '<p>Snapshots:</p><ul><li>How do you like your eggs?</li></ul><p>PP Content:</p><ul><li>Cuisine/Restaurant Types: Chinese, Mexican, Thai, Indian, Fast food, etc</li><li>Breakfast: Toast, pancakes, muffins, etc</li><li>Different Ways to Cook Eggs: Scrambled, hardboiled, poached, etc</li><li>Appetizers/Sides: Egg rolls, queso, hummus, soup, fries, etc</li><li>Entrees: Salad, taco, pizza, lasagna, etc</li></ul>',
              note: null
            },
            {
              id: '7.5',
              name: 'Recipes',
              content: '<p>Snapshot:</p><ul><li>One Hand Occupied?</li><li>Measurements</li><li>Adding Ingredients</li></ul><p>PP Content:</p><ul><li>Measurements: teaspoons, measuring cups, ounces, cup, pint, quart, gallon</li><li>Ways of Preparing Food: Snapping ends of green beans, mince, peel, slice, etc</li><li>Ways of Mixing food: Add, pour, stir. whisk, mixer</li><li>Cooking Methods: Bake, fry, boil, steam, sauté, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '8',
          name: 'Unit 8',
          title: 'People Among Us',
          link_1: 'https://drive.google.com/drive/folders/1Dd-lfJut5wGiKGxWw40jQSGXIDTypNTz?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1PrNDbmx0jjRj4UPzQJTgN6XgVSXZ4Rea?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1Jr9UIW6wJghrwllEjV58K--5XkQNkayn?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1mcjtNmB8IypjmwEq44qthzTKSg0EKT60?usp=sharing',
          glances: [
            {
              id: '8.1',
              name: 'People',
              content: '<p>Snapshots:</p><ul><li>Ethnicity and Ethnic Groups</li></ul><p>PP Content:</p><ul><li>Life Stages: Born, baby, child, teen, college-age/20’s, middle-age, senior citizen, elder/older, young/younger, dead</li><li>Ethnicity and Ethnic Groups: American/Indian/Native American/First Nation or Alaska Native, Asian, Black or African American, Latino/Latina/Latinx, Indigenous peoples, Middle East, East Indian, Multi-ethnic/Multiracial, Native Hawaiian or Other Pacific Islander, White</li></ul>',
              note: null
            },
            {
              id: '8.2',
              name: 'Physical Descriptions',
              content: '<p>Snapshots:</p><ul><li>Describing Others</li><li>Present and Not Present</li><li>KNOW</li><li>Commenting on Appearances</li><li>POTBELLY and PREGNANT</li><li>Tattoos</li><li>Decades</li></ul><p>PP Content:</p><ul><li>Heights: Little People, short, average, tall, growing up</li><li>Body Types: Skinny/slim, average, chubby and cute!, muscular</li><li>Hair Colors: Blonde, red hair, black hair, grey hair, white, dye, etc</li><li>Hair Types: Tight curls, curly, wavy, straight</li><li>Hair Lengths: Short, long, bald, etc</li><li>Facial Hair: Thickness of mustache/beard, shapes of mustache/beards, 5 o’clock shadow/scruffy, etc</li><li>Eyebrows: Thickness of eyebrows</li><li>Facial Features: Long eyelashes, big eyes, teeth gap, freckles, scars, etc</li><li>More Features: Tattoo, body piercing, earplugs</li></ul>',
              note: null
            },
            {
              id: '8.3',
              name: 'Clothing',
              content: '<p>Snapshots:</p><ul><li>Identify then Describe</li></ul><p>PP Content:</p><ul><li>Tops: T-shirt, tank top, blouse, sleeve length, sweater, turtleneck, jacket, etc</li><li>Necklines &amp; Collars:&nbsp; V-neck, scoop, collar, disco collar, ruffle, etc</li><li>Pants &amp; Shorts: different length, type of pants, overalls, etc</li><li>Dresses &amp; Skirts: different length &amp; types</li><li>Suits, Tuxedos &amp; Uniforms</li><li>Sleepwear, Undergarments &amp; Swimsuits: Pajamas, undies, swim shorts, etc</li><li>Pockets: front, back, shirt, hidden</li><li>Sizes: XS, S, M, L, XL</li><li>Conversation Starters #2</li><li>Accessories: Purse, wallet, gloves, scarves, bowties, hats, rings,&nbsp; watches, etc</li><li>Type of Glasses: Eyeglasses, sunglasses</li><li>Earrings: Dangling, loop, fish-hoop, stud, etc</li><li>Necklaces: length, texture, sizes, etc</li><li>Socks:&nbsp; lengths &amp; pattern, stockings/pantyhose</li><li>Footwear:&nbsp; Heels height, running shoes, flip-flops, slippers, boots, etc</li><li>Patterns: solid/plain, strips, flowery, patterns, polka dots, zigzag, animal prints, etc</li></ul>',
              note: null
            },
            {
              id: '8.4',
              name: 'Culture',
              content: '<p>Snapshots:</p><ul><li>Accessibility</li><li>Cultural Pride</li><li>Deaf & DeafBlind Gain</li><li>Identity First</li><li>-isms</li><li>National Deaf Organizations</li></ul><p>PP Content:</p><ul><li>Culture</li><li>Access</li><li>Barriers</li><li>Additional Identities</li><li>Civics</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '9',
          name: 'Unit 9',
          title: 'My Home',
          link_1: 'https://drive.google.com/drive/folders/12kI2VPm-me86OeSgCF-FT5GXooXNlZvk?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/12N0wx03ZJbU_enFtJ3pO7XiS6C5ECqGR?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1MMWM1mBsPbsfUgpcqoStdszEfOOhO1NS?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1e-SSfiZBkephKkbdCkqWi8Gp68wr-HUn?usp=sharing',
          glances: [
            {
              id: '9.1',
              name: 'Floor Plan',
              content: '<p>Snapshots:</p><ul><li>Ordinal Numbers – Floors &amp; Doors</li><li>Deaf Space</li></ul><p>PP Content:</p><ul><li>Ordinal Numbers – Floors (1st floor, 2nd floor, so on)</li><li>Lobby &amp; Hallway: Intercom, mailbox, stairway, elevator, deadbolt, fire alarm, etc</li><li>Rooms: Bedroom, kitchen, living room, bathroom, attic, basement, storage room, etc</li><li>Home Layouts: Studio &amp; 1 bathroom, 1B/1B, 2B/2 ½ B.</li></ul>',
              note: null
            },
            {
              id: '9.2',
              name: 'the Exterior',
              content: '<p>Snapshots:</p><ul><li>Dinner\'s Ready!</li></ul><p>PP Content:</p><ul><li>Around Your Home: Mailbox, driveway, gate, balcony, front door, patio, etc</li></ul>',
              note: null
            },
            {
              id: '9.3',
              name: 'The Interior',
              content: '<p>Snapshots:</p><ul><li>The Kitchen</li></ul><p>PP Content:</p><ul><li>Kitchen &amp; Dining Room Items: Cabinet, dishwasher, refrigerator, sink, table, fork, plate, cup, etc</li><li>Living Room Items: Couch, fireplace, lamp, houseplant, etc</li><li>Bathroom &amp; Bedroom Items: Mirror, faucet, bath towel, toothbrush, bed, pillow, baby room, drawers, crib, etc</li><li>Laundry Items: Washer, dryer, soap, iron, etc</li><li>Home Entertainment: Cable TV, DVD, streaming, video gaming, etc</li></ul>',
              note: null
            },
            {
              id: '9.4',
              name: 'Housework',
              content: '<p>Snapshots:</p><ul><li>Doing Chores Anytime</li></ul><p>PP Content:</p><ul><li>Living Room: Dust, put away, polish, vacuum, etc</li><li>Kitchen: Wash dishes, recycle, wipe, mop, etc</li><li>Bedroom: Make bed, change the sheets, fold-put away-hang clothes</li><li>Laundry: Sorting, loading, hanging, ironing, etc</li><li>Household Problems &amp; Repairs: Power out, leaking roof, clogged toilet, etc</li><li>Home-related Professionals: Carpenter, electrician, plumber, exterminator, etc)</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '10',
          name: 'Unit 10',
          title: 'Finances and the Workplace',
          link_1: 'https://drive.google.com/drive/folders/1Io7uenHFt0zUD8kTohhAF5ZnV3dvcRQg?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/14USGabR97P2IqcO9lyz8WLX7pfYL30So?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1pnFMVPfVu1X1Va9c2D4EzFQbYOdxxy9N?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1wqIYB2qzkRcLAZe52dB5Iu9WTo5RZbey?usp=sharing',
          glances: [
            {
              id: '10.1',
              name: 'Workplace',
              content: '<p>Snapshots:</p><ul><li>Videophones (VP)</li></ul><p>PP Content:</p><ul><li>The Workplace: Factory, construction site, post office, educational institution, working from home, etc</li><li>Office Supplies &amp; Equipment: Laptop, computer, videophone, printer, tape, stapler, conveyer belt, forklift, etc</li></ul>',
              note: null
            },
            {
              id: '10.2',
              name: 'Careers',
              content: '<p>Snapshots:</p><ul><li>Agent Markers</li></ul><p>PP Content:</p><ul><li>Careers: Accountant, Artist, Baker, Computer Software Engineer, Delivery Person, Florist, Interpreter, Model, Nurse, Pilot, Reporter, Server, Welder, etc</li><li>Job Search Process: Networking, job search online, fill out the application form, interview, be presentable, making impressions, etc</li><li>Additional Job Search Terms: Competitive, training, salary, negotiate, advantages &amp; disadvantages, etc</li></ul>',
              note: null
            },
            {
              id: '10.3',
              name: 'Job Skills and Activities',
              content: '<p>Snapshots:</p><ul><li>WATCH</li><li>WASH</li></ul><p>PP Content:</p><ul><li>Warehouse/Manufacturing: Assemble, parts, forklift, shipping, etc</li><li>Office: Answer the phone, meeting, filing, enter data, etc</li><li>Restaurant: Prep, cook, delivery, bus/clean, etc</li><li>Home Services: Furniture assemble, assist, inspect, repair, etc</li><li>Job Responsibilities – Miscellaneous: Design, develop, sell, supervise, etc</li><li>Soft Skills: Leadership, problem-solving, team player, work ethics, creativity, etc</li></ul>',
              note: null
            },
            {
              id: '10.4',
              name: 'Payday',
              content: '<p>Snapshots:</p><ul><li>Relay Service Industry</li><li>Talking About Money</li><li>Cents</li><li>Dollar</li><li>Dollars and Cents</li></ul><p>PP Content:</p><ul><li>Income: Earning/Salary, government assistance programs, retirement/pension, pay raise, pay cut</li><li>Paycheck: Direct deposit, overtime, gross pay, personal leave, etc</li><li>Benefits &amp; Deductions: Federal income tax, health insurance, retirement plan, etc</li><li>Bills &amp; Payments: Rent, mortgage, loans, insurances, bills, etc</li><li>Banking: Cash, check, debit card, credit card, save, savings, interest rates, etc</li><li>Personal Financial Status: Economy, budget, broke, pays well, credit score, etc</li></ul>',
              note: null
            },
            {
              id: '10.5',
              name: 'Shopping',
              content: '<p>Snapshots:</p><ul><li>Deaf Owned businesses</li></ul><p>PP Content:</p><ul><li>Grocery Store: Aisle, shopping cart, lines, checkout, etc</li><li>Shopping Mall: Department stores, different kind of stores (jewelry, candy, beauty, shoes, etc)</li><li>Shopping Experience: Online shopping, trying on, price tag, materials, on sale, return, exchange, etc</li></ul>',
              note: null
            },
            {
              id: '10.6',
              name: 'Major Purchases',
              content: '<p>Snapshots:</p><ul><li>Economic Justice</li><li>Age of Objects</li></ul><p>PP Content:</p><ul><li>Car Shopping: Research, new or used, mileage, etc</li><li>Vehicle Features: Standard or automatic, sunroof, airbags, GPS, rear/backup camera, etc</li><li>Buying a Car: Loan application, monthly payment, down payment, trade-in, etc</li><li>Home Buying: Putting the house up for sale, offer, inspections, etc</li><li>Cost of Higher Education: Admission application, take a test, acceptance letter, scholarship, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '11',
          name: 'Unit 11',
          title: 'Wellness and Fitness',
          link_1: 'https://drive.google.com/drive/folders/15GzrOgun8FyLylQesTPrkB1sr4CbktdY?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1fBWwmGNSntmbozmuvzG4xVanpde5CD3y?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1lO4Oat2-7zGBYvhDPWUcrJ-7Y1tQZOsY?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1dlz9b0g0ZrtER_q8QeiEiSk7f3XgWpYT?usp=sharing',
          glances: [
            {
              id: '11.1',
              name: 'Health-Related Settings',
              content: '<p>Snapshots:</p><ul><li>Medical Interpreting</li></ul><p>PP Content:</p><ul><li>Health-Related Settings: Patient, vital signs monitor, IV, nurse call, ultrasound scanner, crutches, gurney, ambulance, ER, etc</li></ul>',
              note: null
            },
            {
              id: '11.2',
              name: 'Health-Related Careers',
              content: '<p>Snapshots:</p><ul><li>Deaf People in the Medical Field</li></ul><p>PP Content:</p><ul><li>Health-Related Careers: Doctor, nurse, surgeon, anesthesiologist, EMT, radiologist, dietician, admission clerk, acupuncturist, etc</li></ul>',
              note: null
            },
            {
              id: '11.3',
              name: 'Wellness and Nutrition',
              content: '<p>Snapshots:</p><ul><li>Height</li></ul><p>PP Content:</p><ul><li>Wellness &amp; Nutrition: Healthy, self-care, vitamins, check-up, vaccines, stress, reduce, greasy food, etc</li><li>Exercise: Personal trainer, swimming, sit-ups, stretching, running, etc</li></ul>',
              note: null
            },
            {
              id: '11.4',
              name: 'Common Illnesses and Treatments',
              content: '<p>Snapshots:</p><ul><li>Temporal Aspects – Severity &amp; Frequency</li><li>Fingerspelling Medicine</li></ul><p>PP Content:</p><ul><li>Common Illness: Flu, colds, ear inflection, allergy, chickenpox, arthritis, stroke, cancer, etc</li><li>Symptoms/Signs: Dizziness, fever, seizure, nasal congestion cough, nausea, etc</li><li>Symptoms – Location: Rash, itching, swollen, bite, bruise, cut, etc</li><li>Treatments: Rest, bandage wrap, tweezers, cast, ice pack, eye drops, ointment, etc</li><li>Medicine Instructions: RX, OTC, dosage, warning label, take with food or milk, etc</li><li>To Follow or Not to Follow Doctor’s Orders: Regularly, on and off, forgot to take medicine, etc</li></ul>',
              note: null
            },
            {
              id: '11.5',
              name: 'Health-Related Emergencies',
              content: '<p>Snapshots:</p><ul><li>Text to 911</li></ul><p>PP Content:</p><ul><li>Emergency Room Visit Reasons: Going into labor, heart attack, choking, accident, animal bites, stung, allergic reaction, broken bone, stabbed, etc</li><li>In the Emergency Room: Prioritized, emergency medical ID, stitches, MRI, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '12',
          name: 'Unit 12',
          title: 'Destinations',
          link_1: 'https://drive.google.com/drive/folders/1NwGFw-pkFI5ZzD1WXyYNcEcKDOjjR3Yl?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1cjGGHJjBpkG4w3zatbMOP2XTP-6795l2?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1CWUpee4IGu3vmSIz_EirEUqAGmv5vFMC?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1GmSZWJBtNsuCAqr8o9kAD5qS3whbjwqI?usp=sharing',
          glances: [
            {
              id: '12.1',
              name: 'Seasons and Weather Conditions',
              content: '<p>Snapshots:</p><ul><li>Conditional Sentences</li></ul><p>PP Content:</p><ul><li>Seasons: Spring, Summer, Fall, Winter</li><li>Weather Conditions: Sunny, rain, drizzle, snow, blizzard, foggy, etc</li><li>Temperatures: Degree, Celsius Fahrenheit, high and low temperatures, hot, cold, etc</li></ul>',
              note: null
            },
            {
              id: '12.2',
              name: 'Natural Disasters',
              content: '<p>Snapshots:</p><ul><li>FEMA and Deaf People</li></ul><p>PP Content:</p><ul><li>Natural Disasters: Earthquake, flood, tornado, drought, wildfire, volcanic eruption, etc</li><li>Natural Disaster-Related Terms: Announcement, warning, flee, trapped, scared, FEMA</li></ul>',
              note: null
            },
            {
              id: '12.3',
              name: 'Maps and Directions',
              content: '<p>Snapshots:</p><ul><li>Giving Directions – Landmarks</li></ul><p>PP Content:</p><ul><li>Prepositions of Place &amp; Giving Directions: Next to, across from, through, go straight, over, under, turn left/right, etc</li><li>Streets &amp; Highways: Highway, two-lane, right-middle-left lane, intersection, corner, toll, merge, exit, etc</li><li>Road Signs: Stop, speed limit, dead-end, yield, highway marker, etc</li></ul>',
              note: null
            },
            {
              id: '12.4',
              name: 'Road Trip',
              content: '<p>Snapshots:</p><ul><li>Landmark Influenced Signs</li></ul><p>PP Content:</p><ul><li>Road Trip Tips: Plan ahead, calculate gas mileage, prepare for emergencies, etc</li><li>Road Trip Pre-check: Inspection, check oil, brake, radiator, etc</li><li>Essential Items You Should Keep in Your Car: Spare tire, jumper cables, road maps, first-aid kit, road safety flares, blanket, etc</li><li>Common Road Trip Problems: Lost, flat tire, engine overheat, battery dies, etc</li><li>Landmarks: Statue of Liberty, Golden Gate Bridge, CN Tower, Grand Canyon, Niagara Falls, Redwood National Park, etc</li><li>Landscapes and Waterscapes: Desert, plain, forest, rolling hills, mountain, river, lake, beach, ocean, etc</li></ul>',
              note: null
            },
            {
              id: '12.5',
              name: 'World Map',
              content: '<p>Snapshots:</p><ul><li>Country Signs</li><li>Travel Vloggers</li></ul><p>PP Content:</p><ul><li>7 Continents and Major Oceans: Earth, World, International, North America, Africa, Asia, etc</li><li>Countries: different countries</li><li>Travel-Related Terms: Visited/been there, backpacking, travel, tour, tour guide</li></ul>',
              note: null
            },
            {
              id: '12.6',
              name: 'Traveling Around the World',
              content: '<p>Snapshots:</p><ul><li>Communicating Abroad</li><li>Deaf Travel</li></ul><p>PP Content:</p><ul><li>Travel Plans: Research, reservation, itinerary, border, authentic, museum, cruise, train, bus, etc</li><li>The Airport: Check in, E-ticket, On-time, delayed, cancelled, missed connection, security screening, passport, board a plane, turbulence, customs, etc</li><li>Lodging: Hotel, motel, hostel, star rating, front desk, room service, etc</li><li>Popular Travel Destinations: about 28 different places</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '13',
          name: 'Unit 13',
          title: 'Narrative Building Blocks',
          link_1: 'https://drive.google.com/drive/folders/1_qll9vYbLrEZWAbEzKlna3ZAcoR7aT6n?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1wx8wEh0QhcO19LZKBo2b_T6JrcEp0cQP?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1lpAyxDxWj7j5iNWgPkjnonuWXiS5egq0?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1ms_o-_RmdNXjFpAXGvyZknTF735kXZog?usp=sharing',
          glances: [
            {
              id: '13.1',
              name: 'Describing Concepts',
              content: '<p>Snapshots:</p><ul><li>Adjectives</li><li>Intensifiers</li></ul><p>PP Content:</p><ul><li>Sizes: Small (OO), moderate in size-distance-time related (MM), large/thick (CHA or ARCH)</li><li>Intensifiers: MM, FF, OO, “puffed cheek”, AHH, BRR, SOA, IS/INTENSE (CUTE, SHINY, SMART, COLD)</li><li>Shape, Surface &amp; Size: MM, AHH, OO, CHA, FFF, TH/UR, AHH (curvy hallway, small hallway, warped floor, etc)</li><li>Appearances: OO, MM, CHA, ARCH, puffed cheek, FFF, SOA, IS/INTENSE (fur, clean-messy, clean-dirty)</li><li>Taste Touch: Scoville scale, Coffee taste, pillow softness, skin roughness, etc</li><li>Feelings &amp; Personality/Qualities: Grumpy, curious, shy, cautious, playful, smart, proud, etc</li></ul>',
              note: null
            },
            {
              id: '13.2',
              name: 'Onomatopoeia',
              content: '<p>Snapshots:</p><ul><li>Onomatopoeia</li><li>Being Noisy</li><li>Speaking Gibberish</li></ul><p>PP Content:</p><ul><li><p>Onomatopoeia…</p><ul><li>Animal: Dog, cat, cow, duck, bee, lion, gorilla, etc</li><li>Action: Ice cream fall, vacuum, clock ticking, car-related, etc</li><li>Body &amp; Bodily Functions: burp, snore, gargle, cough, fart, etc</li><li>Visual Noise: light flickering, twinkling stars, railroad crossing light flashing, car headlight, dimmed light, TV static, etc</li><li>Elements: Earth, air/wind, fire, water,</li></ul></li></ul>',
              note: null
            },
            {
              id: '13.3',
              name: 'Colors',
              content: '<p>Snapshots:</p><ul><li>Color Ranges</li><li>Color Mixing</li><li>Identifying Products Through Color</li></ul><p>PP Content:</p><ul><li>Color Ranges &amp; Color mixing: Apple red to dark red, tan to mocha, black to pitch black, so on.</li></ul>',
              note: null
            },
            {
              id: '13.4',
              name: 'Opening & Closing',
              content: '<p>Snapshots:</p><ul><li>Diamond-Style Stories</li><li>Openings and Closings</li><li>Mouthing Time Expressions</li></ul><p>PP Content:</p><ul><li>Time-Related Non-Manual Markers: CS/Intense MM, AHH (ancient , long time ago, recently, soon, tomorrow, far future, etc)&nbsp;</li><li>Telling a Short Story using Time Expression: Once upon a time…Someday in the far-distance future, futuristic, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '14',
          name: 'Unit 14',
          title: 'Narratives',
          link_1: 'https://drive.google.com/drive/folders/1omR-1uweq47iXhAL3uQBy4wPkWjPmqm9?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1xQ9DT6LzDWwhhpZ2SkFqSzteRN1XvJXC?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1R3wfeVqAr0hAev8G1NyqfgSC3P7btx4V?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1MtFuXOq0SpnuipX-UEPxP_5CMEoGrARl?usp=sharing',
          glances: [
            {
              id: '14.1',
              name: 'Who did What to Whom?',
              content: '<p>Snapshots:</p><ul><li>Who Did What to Whom</li><li>TO</li><li>To and Adverb</li><li>To and Location</li></ul><p>PP Content:</p><ul><li>Possible Directions: —GIVE— (one time, repeatedly, randomly, etc), ASK, TEACH, PITY, etc</li><li>TO+Adverbs: ____-MEET(shy), ____-TOSS(clumsily), etc</li><li>TO+Location: CHILD-HIGH-FIVE, PARENT-TEACH, etc</li><li>Not all verbs in ASL can be directional: Drink, cry, lie, work, eat, etc</li></ul>',
              note: null
            },
            {
              id: '14.2',
              name: 'Constantly and Repeatedly',
              content: '<p>Snapshots:</p><ul><li>Temporal Aspects</li><li>Temporal Aspect Examples</li></ul><p>PP Content:</p><ul><li>Ordinarily: Sign (not modified) + MM</li><li>Regularly &amp; Concentratedly: Sign (modified) + press lips/tight lips</li><li>Hurriedly: Sign (modified) + intense(mouth)</li><li>Continually: Sign (modified) + small-AHH</li><li>Prolongedly: Sign (modified) + larger-AHH</li><li>Prolongedly &amp; Repeatedly: Sign (modified) + STA STA</li><li>Now try with different action verbs: WORK, DRIVE, JUMPING , DANCING, PLAYING VIDEO GAMES, SCRATCHING, etc</li></ul>',
              note: null
            },
            {
              id: '14.3',
              name: 'Becoming the Character',
              content: '<p>Snapshots:</p><ul><li>Surrogates</li><li>Personification</li><li>Anthropomorphism</li><li>Perspective</li></ul><p>PP Content:</p><ul><li>Surrogates examples: Toddler playing, nosy neighbor, upset driver, etc</li><li>Personification examples: Plane, palm tree, lawn mower, etc</li><li>Anthropomorphism examples: Hummingbird, shark, crab, etc</li><li>Perspective examples: Looking up in water, from inside a mailbox, looking down from a building, etc</li></ul>',
              note: null
            },
            {
              id: '14.4',
              name: 'Putting it All Together',
              content: '<p>Snapshots:</p><ul><li>History of ASL Storytelling</li><li>Deaf Narrative Themes</li><li>Storytelling Components</li><li>Telling Stories</li><li>Translated Stories</li></ul><p>PP Content:</p><ul><li>Story Structure: Opening, climax, summary, close, diamond-shape</li><li>Essential Components: shoulder-shift, character, visualize, rhythm, noun, etc</li><li>Types of Stories: 1) Overcoming the obstructions, 2) Rebirth, 3) Quest, 4) Rags to Riches, 5) Tragedy, 6) Comedy, 7) Deaf themed</li><li>Deaf Narrative Themes: 1) The discovery and development of a Deaf identity, 2) Success Story, triumph, 3) The leverage of being Deaf or knowing sign language</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '15',
          name: 'Unit 15',
          title: 'History',
          link_1: 'https://drive.google.com/drive/folders/1SGakpV2xiVqQQaU0Mml3KMoUEK0W8jNH?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1EqLdtUcXHu0LKInXAckMrgQy6NN2FrMq?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1StR9_U9PuPxgYiNNiw70EkkyoCRuoi0i?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1mmzhQ-SZlCjXP_Hflsc3ZKmF06kKTAaR?usp=sharing',
          glances: [
            {
              id: '15.1',
              name: 'US and Canadian History',
              content: '<p>Snapshots:</p><ul><li>Registers</li><li>Specialized Discourses</li><li>Year and Year Ranges</li></ul><p>PP Content:</p><ul><li>Before 1400s: Indigenous people</li><li>Late 1400s-1500s: France &amp; Britain explored &amp; colonized Canada &amp; US<br>1500s: Epidemic/pandemic, colonization</li><li>1600s/17th Century: Europe Migrants, slaves, population growth, colonies, Christian, trading</li><li>1700s/18th Century: Revolution, taxation, civil wars, Declaration of Independence, US Constitution, assimilation</li><li>1800s/19th Century: Indian reservations, War of 1812, Industrial Revolution, civil war, abolition, Canada independence</li><li>1900s/20th Century: WW1, WW2, suffrage, The Great Depression, civil rights movement, segregation</li></ul>',
              note: null
            },
            {
              id: '15.2',
              name: 'US and Canadian Deaf History',
              content: '<p>Snapshots:</p><ul><li>Discussing the Past</li><li>Origins of ASL</li><li>Deaf History</li><li>Early ASL</li><li>Deaf and Dumb</li></ul><p>PP Content:</p><ul><li>1500s-1700s: First Deaf School (private school in Spain), first public Deaf school in France</li><li>1800s: American School for the Deaf, Gallaudet University, AGB, oralism, ICED, NAD</li><li>1900-1959: Veditz film, ICSS, AAAD-&gt;USADSF, WFD</li><li>1960-1990: Stokoe, NTID, CC, DPN, ADA, Gary Malkowski</li><li>1991-2017: ASL &amp; LSQ in Canada, VRS, SAB, Nyle</li><li>Technology – Telephone Timeline: Before TTY, TTY device size became smaller &amp; portable, relay services, texting/online chat, VRS, smartphone</li><li>More History Links</li></ul>',
              note: null
            },
            {
              id: '15.3',
              name: 'Breaking Records',
              content: '<p>Snapshots:</p><ul><li>Comparative and Superlative Adjectives</li><li>Autobiographies and Biographies</li></ul><p>PP Content:</p><ul><li>Firsts: “Selfie”, skyscraper, “incandescent” light bulb, football huddle, first films with ASL, solo flight by a woman, moon, Barack Obama, Oscar – Marlee Matlin, NASA – Johanna Lucht, etc</li><li>Incredible Records: Oldest cat, longest fingernails, hottest chili, etc</li><li>Biographies: Sacajawea, Frederick Douglass, Harriet Tubman, Sitting Bull, Cesar Chavez, Maya Angelou, Stephen Hawking, Sonia Sotomayor</li><li>Deaf Biographies Links: Dr. Shirley Allen, Dr. Glenn Anderson, Linda Bove, Leroy Colombo, Leah Hernandez-Katz, William “Dummy” Hoy, and many more!</li><li>More Links: Famous firsts, biography, world records</li></ul>',
              note: null
            },
            {
              id: '15.4',
              name: 'Museums and Historical Tours',
              content: '<p>Snapshots:</p><ul><li>Deaf Museums</li><li>Museum Access</li><li>Great Blacks in Wax Museum</li></ul><p>PP Content:</p><ul><li>Museum Terminology: Artifact, art, sculpture, curator, care, preservation, etc</li><li>Museum Access: Captions/subtitles, script, audio, interpreter, Deaf tour guide, Augmented Reality</li><li>The Experience: Looking back, memory, visualize, fascinating, sad, analyze, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '16',
          name: 'Unit 16',
          title: 'The Human Body',
          link_1: 'https://drive.google.com/drive/folders/1l5GNdzHQoLK9SqfLjvrvohAH6-6M2oJp?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1RAKctDGZFatRIaIgFmHiLK2KTB6YPFuW?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1mgITWeBvbbxYFy42qQ10XzqcbJ_er6h8?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1gS4-usKmhiVrc2BwhqLfmPLUoKZqQL0p?usp=sharing',
          glances: [
            {
              id: '16.1',
              name: 'External Parts',
              content: '<p>Snapshots:</p><ul><li>Describing External Parts</li><li>Sign language Gain</li><li>Universal Design</li><li>Our Senses</li></ul><p>PP Content:</p><ul><li>Depiction – Body: Head, ears, arm, hands, legs, foot, eyebrows, etc</li><li>Depiction – Head &amp; Face: Nodding, shaking, jerk, bow, “taken by surprise”, eyes movement, winking, pupils movement, eyelids, eyelashes, flaring nostrils, etc.</li><li>Depiction – BodyMovements/Positions: Straight, stiff, slouched, etc</li><li>Depiction – Leg Movements/Positions: Running, sprinting, trail running (rough terrain), parkour, slipped, limping, sitting on the floor, legs propped on table, yoga positions, gymnastics positions, etc</li><li>Depiction – Foot/Feet Movement/Positions: Standing, walking, foot-tapping, tip-toe, ballet, sprain, etc.</li><li>Senses: Sight/vision, touch, smell, hearing and taste</li></ul>',
              note: null
            },
            {
              id: '16.2',
              name: 'Internal Parts',
              content: '<p>Snapshots:</p><ul><li>Describing Internal Parts</li><li>Bodily Noises</li></ul><p>PP Content:</p><ul><li>Human Body Systems: Skeletal, immune, nervous, muscular, reproductive respiratory, cardiovascular, digestive</li><li>Internal Organs: Heart, brain, liver, lungs, stomach, kidneys, spleen, colon, small intestine</li><li>Integumentary/Skin System: Skin (layer, protect, injury, etc), hair &amp; nails (healthy, brittle, etc)</li><li>Musculoskeletal System: Bone, ligaments, muscle (spasm, twitch, pulled muscle, etc), nerve/brain stimulate/control, skull, joints, socket joint, etc</li><li>Respiratory System: Lungs, diaphragm, inhale, exhale, blow, hard to breathe, wheezing, hold breath, hiccup, etc</li><li>Cardiovascular/Circulatory System: Blood, heart pumping, circulate, oxygen, clogged, dizzy, etc</li><li>Nervous System: Brain, spinal cord, nerves, disc, neurons, paralysis, tingling, etc</li><li>Immune System: Infection, disease, contagious, prevent, cells, etc</li><li>Digestive System: Mouth, esophagus, stomach, liver, chewing, constipated, burping, bloating, etc</li><li>Urinary System: Kidney, kidney stone, bladder, hold in, accidental, etc</li><li>Reproductive System: Ovaries, Uterus, menstruating, sperm, erection, miscarriage, STD, HIV, condom, protection, etc</li></ul>',
              note: null
            },
            {
              id: '16.3',
              name: 'Medial Procedures',
              content: '<p>Snapshots:</p><ul><li>Medical Field</li><li>Emergency Calls</li><li>Emergency Interpreting</li><li>The Gory Details</li></ul></li></ul></li></ul><p><span style="background-color: #ffff00;">*Warning – some pictures may contain graphic materials. Viewer direction is advised.</span></p><p>PP Content:</p><ul><li>Surgery &amp; Types of Surgery: Elective, semi-elective, exploratory, emergency, transplant, cosmetic, amputation, etc</li><li>Common Surgical Procedures: Laser, microsurgery, suturing, staples, biopsy, grafts, cataract, tonsillectomy, joint replacement, heart bypass, etc</li><li>Preoperative Care: Medical exam, X-ray, blood count, blood types, etc</li><li>Staging for Surgery: Change clothes, vital signs, IV, anesthesia, etc</li><li>Cosmetic/Plastic/Reconstructive Surgery: Cleft, scar repair, weight loss surgery, botox, facelift, breast implants, buttock lift, etc</li><li>Post-operative Care: Catheter, surgical drain, infection, chemotherapy, dialysis, recovery, etc</li></ul>',
              note: null
            },
            {
              id: '16.4',
              name: 'Personal Hygiene',
              content: '<p>Snapshots:</p><ul><li>Deaf Schools</li><li>Deaf Hygienic Practices</li><li>Signing Dentists</li></ul><p>PP Content:</p><ul><li>Personal Grooming/Hygiene: Bath, shampoo, exfoliating scrub, nail clippers, cotton balls, deodorant (slid, roll-on, spray, etc), shave (razor, electric, waxing, etc), picking nose, hands-on handrails, hand washing, disinfectant, sanitizing wipes, etc</li><li>Skin Conditions: Moles, acne, rash, measles, hives, warts, blisters, dry skin, fungus, ingrown nail, ear infection, body odor, etc</li><li>Dental Hygiene: Mouthwash, toothbrush, toothpick, floss, tongue scraper, etc</li><li>Dental Issues: Stained teeth, teeth whitening (and its methods), braces, filling, tooth extraction, dentures, dry mouth, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '17',
          name: 'Unit 17',
          title: 'Sports and Activities',
          link_1: 'https://drive.google.com/drive/folders/1Z9VGA5ou7DfdQhnbQkUn8MvQ9s3Je-HO?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1LaZZfhfx3qhYKn8ZVsMvNohW8fzQUr-W?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1Z9LTbFI2yuPxAvEmdywfT9lqnJaN8GwA?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/14csA1y6SrvicZDF2YYT_pfKnd16BtKRz?usp=sharing',
          glances: [
            {
              id: '17.1',
              name: 'All About Movement',
              content: '<p>Snapshots:</p><ul><li>Describing Movements</li><li>Flying Objects</li><li>Different Perspectives</li></ul><p>PP Content:</p><ul><li>General Movements: Balance, strength, running (speed – accelerating, decelerating, zigzag, etc), crawl, dive, lift, dragging, throw, catch, hit, punch, slap (face, forehead, knee, etc), jump, leap,etc</li><li>Head Movement: Rotation, hearing, getting air (swimming), head dodging the ball (ball missed), head-related metaphors (HEAD-DOWN = busted, guilty, humbled, etc), HEAD-NOD (playing along, not really paying attention, caving in, etc)</li><li>Arm/Hand Movements: Tennis (grip, swinging, etc), baseball/softball (swing, grip, throwing, missed, etc), hockey (bolding &amp; handling, passing the puck, etc), lacrosse (scooping, shoot the ball..etc), curling (holding &amp; sliding granite stones/rocks), football/rugby (holding, passing, catching from different angles, etc), volleyball (serve, block, spiking, etc), swimming, karate/MMA (punch, chop, etc), yoga, skiing, javelin, archery, etc</li><li>Leg Movements: Basketball, yoga, soccer, baseball/softball, skiing &amp; snowboarding, volleyball &amp; beach volleyball, synchronized swimming, diving, karate/MMA, football/rugby, field &amp; track, hurdles, gymnastics, skateboarding, bobsledding, etc</li><li>Feet Movements: Skiing &amp; snowboarding, soccer, ballet, tap dancing, etc</li><li>Body Movements: Body bending, twist, etc</li><li>Ball Movements: Fastball, slow, curve, bad throw, etc</li><li>Exercises &amp; Motions: Squat, pushup, plank, lunges, jumping jacks, crunches, etc</li><li>Different Views/Point of Views: Player’s view (on the field, playing), field view (coach’s view, fans’ view), sideline/dugout, audience’ view, bird’s eye/drone view (view from the top, across the field), etc</li></ul>',
              note: null
            },
            {
              id: '17.2',
              name: 'Individual Sports and Activities',
              content: '<p>Snapshots:</p><ul><li>Sounds in Sports</li><li>Legends and Leaders</li><li>Signing as an Advantage</li></ul><p>PP Content:</p><ul><li>Different Types of Individual Sports: Archery, bowling, boxing, disc golf, fencing, gaming, racquetball, rowing, wrestling, etc</li><li>Sportsmanly: Competitive, ambitious, honest, challenge, struggle, tough, overcome, accept, mature, etc</li><li>Unsportsmanlike: Talk trash, cheating, blaming, bitter, belittle, rude, arrogant, discouraged, give-up, complain, etc</li><li>Roles: Opponent, referee, teammate, fans, etc</li></ul>',
              note: null
            },
            {
              id: '17.3',
              name: 'Team and Competitive Sports',
              content: '<p>Snapshots:</p><ul><li>Access to Sporting Events</li><li>Deaf Gain in Team Sports</li><li>Deaf Sporting Organizations</li><li>Team Signs</li><li>Deaf School Sports and Rivalry</li><li>Game Periods</li></ul><p>PP Content:</p><ul><li>Team Sports: Basketball, cheerleading, curling, football, ice hockey, etc</li><li>Game Periods: Full game, two halves, start of the period (mouthing: MM), close to end of period (mouthing: CS), halftime, overtime, etc</li><li>Number of Players Playing: One-on-one, five-on-five, 11-players, etc</li></ul>',
              note: null
            },
            {
              id: '17.4',
              name: 'Sporting Facts and Stats',
              content: '<p>Snapshots:</p><ul><li>Sport Statistics</li><li>Stadiums and Seating</li><li>Deaflympics</li><li>Sports Injuries</li></ul><p>PP Content:</p><ul><li>Stadium Features: Baseball field, multi-use stadium, domed stadium, jumbovision, floodlights, tailgating, skybox, concession areas, hot dogs, popcorn, entrance from fan’s perspective, entrance from player’s perspective, sportscaster, converts, etc</li><li>Sporting Equipment: Goalposts, cleats, gloves, nets, goggles, skates, rails, helmet, chin-strap, mat, ball machine, etc</li><li>Team Roles &amp; Responsibilities: First string, coach, statistician, umpire, massage therapists, etc</li><li>Numbers in Sports: Cardinal (counting numbers), ordinal (ordering) numbers, ranking, rating, height, weight, age, years, decimals, percentage, points, scores, records, jersey numbers, yards, salary, etc</li><li>Injuries Related: Muscle torn, groin pull, ACL tear, concussion, brace, heating pad, therapy, warm-up, etc</li></ul>',
              note: null
            },
          ]
        },
        {
          id: '18',
          name: 'Unit 18',
          title: 'Animals and Terrains',
          link_1: 'https://drive.google.com/drive/folders/1EG9QO_s-CsprO8FTxcVzigVqNVfiSvJa?usp=sharing',
          link_2: 'https://drive.google.com/drive/folders/1ipQ3m55pIC1cdOHTFYURx-B53VULDFeS?usp=sharing',
          link_3: 'https://drive.google.com/drive/folders/1YY3c2bW7fEcCe72xZOoPUbl2q093M6Sz?usp=sharing',
          link_4: 'https://drive.google.com/drive/folders/1uGxYktdj3XHoftYftAjCN5683tC7sGDK?usp=sharing',
          glances: [
            {
              id: '18.1',
              name: 'How Animals Move',
              content: '<p>Snapshots:</p><ul><li>Animal Plurality</li><li>Depicting Animal Movement</li></ul><p>PP Content:</p><ul><li>Animal Movement: Bipedal, quadrupedal, six-legs (e.g. insects), eight-legs, multi-armed (e.g. centipedes, vertical-fish, horizontal-fish (e.g. rays), flying bird, etc</li><li>Parts Used for Moving: legs, wings, fins, dorsal fin, tails, etc</li><li>Self-propelled: running, jumping, hopping, swimming, flying, soaring, gliding</li><li>Passive Locomotion: Sailing, kiting, rolling</li><li>Locomotion Modes – Aquatic:&nbsp; Buoyancy, jet propulsion, thrust, etc</li><li>Locomotion Modes – Terrestrial: Galloping, balancing, jumping, waddling, slithering, etc</li><li>Locomotion Modes – Arboreal (trees, etc): Swing, hanging, jumping from-branch-to-another, etc</li><li>Locomotion Modes – Fossorial(underground): Burrowing, nose-digging, tunnels, etc</li><li>Locomotion Modes – Aerial (air): Gravity, lift, “V” formation, flapping, gliding, pick-up-with-talons, pick-up-with-beaks, attacking, etc</li><li>Animal Interaction: Head butt, flight, escape, migration, lick, hide, camouflage, etc</li></ul>',
              note: null
            },
            {
              id: '18.2',
              name: 'Mammals, Birds & Arthropods',
              content: '<p>Snapshots:</p><ul><li>Deaf Cats and Dogs</li><li>Weather and Climate</li><li>Animal Habitats</li></ul><p>PP Content:</p><ul><li>Terrains: Earth, tectonic plates, mountain, hilly, cliff/ridge, valley, cave, grassland/plains, forest, jungle, desert, swamp, island, urban, water current, erosion, etc</li><li>Weather and Climate: Weather forecast, paleoclimatology, barometer, ocean temperature, global warming, equator, tropical, sea level, high altitude, sunny, snowy, etc</li><li>Habitats: Flora, fauna, moss, rock, vernal pool, food, predator, prey, shelter, invasive alien species, pests, deforestation, draining marshland, bottom trawling, pollution, etc</li><li>Mammals: Milk, womb, marsupials, large brains, echolocation, evolution, herbivores, carnivorous, armadillo, bear, deer, fox, lion, porcupine, tiger, walrus, etc</li><li>Birds: Feather, preen, beaks, nest, eggs, flocking, blue jay, eagle, owl, pelican, penguin, woodpecker, etc</li><li>Arthropods: Exoskeleton, segmented body, appendages, ants, bees, butterflies, crabs, dragonfly, mosquito, praying mantis, scorpions, spiders, ticks, etc</li></ul>',
              note: null
            },
            {
              id: '18.3',
              name: 'Fish, Reptiles and Amphibians',
              content: '<p>Snapshots:</p><ul><li>&nbsp;Signing Fish, Reptiles, and Amphibians</li><li>Water</li><li>Natural Disasters</li></ul><p>PP Content:</p><ul><li>Their Parts and How They Move: Gills, eyes shape, eyes position, eyes movements, scales, vocal sac, fangs, forked tongue, mandible (fish jaws), bottom-feeder, fish jumping out of the water, fish flopping on land, etc</li><li>How Fish, Reptiles &amp; Amphibians Touch and Feel: Moist, slippery, clammy, scaly, smooth, etc</li><li>Fish: Anglerfish, catfish, clownfish, marlin, piranha, salmon, seahorse, shark, tuna,etc</li><li>Reptiles: Alligators, lizard, snake, sea turtle, tortoise, dinosaur, etc</li><li>Amphibians: Toads, frogs, tadpole, salamander/newt</li><li>Life Cycle: Spawning, carry eggs/babies on its back, mouth-brooders, male pregnancy/pouch (i.e. seahorses), nesting/burying eggs, guarding eggs, abandon eggs, mutations,etc</li><li>Offensive &amp; Defensive Behaviors: Camouflage, poison, sting, bite, bright colors, flee, fight, projectiles, etc</li><li>Feeding Behaviors: Swallowing its prey whole, eats, cannibalism, fishing pole-like appendage, constrict, etc</li><li>Water-related: Still-water, rough-water, waterfall, low/high tide, river, pond, wetland, water cycle, etc</li></ul>',
              note: null
            },
            {
              id: '18.4',
              name: 'Interesting Animal Facts',
              content: '<p>Snapshots:</p><ul><li>A Deaf Dolphin</li><li>Diverse Ways of Sensing</li></ul><p>PP Content:</p><ul><li>Reactions to Interesting Animal Facts: Only-one, different, fascinate, intriguing, scary, cute, ugly, record, best, slow/fast, strong, never-heard, serious, true-biz, wow, shock, lie-not-true, made-up, doubt, pensive, sad, hope</li><li>Conversation Starters</li><li>Group Discussion</li><li>ASL activity</li><li>SO-FAR YOU LEARN WHAT?</li></ul>',
              note: null
            },
          ]
        },
      ],
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSubscribed() {
      return this.$store.state.auth.user.is_subscribed;
    },
    isSearchDisabled() {
      return (!this.search.trim())
    },
    tagsWithTerms() {
      return this.relatedTags.filter(tag => tag.terms.length > 0)
    },
    // This is for making it easier to use an index for showing the videos
    collectedTerms() {
      return this.terms.concat(this.relatedTags.reduce((acc, tag) => acc.concat(tag.terms), []))
    }
  },
  watch: {
    // When collected terms change, reset selected_index to -1 so it doesn't show old result.
    collectedTerms() {
      this.selected_index = -1;
    },
    // Watch selected_index for changes and update the selected_term.
    selected_index() {
      if (this.selected_index < 0) { return; }

      const { title, content } = this.collectedTerms[this.selected_index];
      this.selected_term = {
        title,
        content: setUrlParams(content, { loop: this.loop, playsinline: this.playsinlineEnabled })
      }
    },
    search() {
      if (this.suggestedTermsEnabled && this.search.trim().length >= 3) {
        this.getSuggestedTerms()
      }
      if (this.autoupdateResultsEnabled) {
        this.loading = true;
        debounce(() => this.findSigns(this.search), 500);
      }
    },
    searchOption() {
      localStorage.setItem('wts.searchOption', JSON.stringify(this.searchOption))
      this.findSigns(this.search)
    }
  },
  async mounted() {
    this.searchOption = JSON.parse(localStorage.getItem('wts.searchOption')) || false

    this.relatedTagsEnabled = await FeatureService.isEnabled("related_tags")
    this.suggestedTermsEnabled = await FeatureService.isEnabled("suggested_terms")
    this.autoupdateResultsEnabled = await FeatureService.isEnabled("autoupdate_results")
    this.tsvSearchEnabled = await FeatureService.isEnabled("tsv_search")
    this.playsinlineEnabled = await FeatureService.isEnabled("playsinline")
    if (this.autoupdateResultsEnabled && this.suggestedTermsEnabled) {
      console.warn("Both suggested_terms and autoupdate_results features are enabled, disabling suggested_terms feature");
      this.suggestedTermsEnabled = false;
    }

    const input = document.getElementById('search_input')
    this.moon = new Modal(document.getElementById('moon'))

    if (this.$route.query?.session_id) {
      const { session_id: sessionId } = this.$route.query
      this.sessionId = sessionId;
    }

    if (this.$route.query?.q) {
      input.value = this.search = this.$route.query.q;
      debounce(() => this.findSigns(this.search), 0)
    } else {
      input.focus()
    }
    window.addEventListener("keydown", this.navigateModal);
  },
  created() {
    if (this.loggedIn) {
      trackAuthentication({ action: 'search:checkSubscription', user: this.$store.state.auth.user })
      this.checkSubscription()
    } else {
      trackAuthentication({ action: 'search:unauthenticated', user: this.$store.state.auth.user })
      this.$router.push("/login");
    }
  },
  unmounted() {
    window.removeEventListener("keydown", this.navigateModal);
  },
  methods: {
    showSigns(index) {
      this.selected_index = index;

      track('select_term', {
        selectedTerm: this.selected_term.title,
        searchTerm: this.search
      })
      this.moon.hide()
      this.moon.show()
    },
    nextSigns() {
      if (this.selected_index < this.collectedTerms.length - 1) {
        this.selected_index++
        track('next_term', {
          selectedTerm: this.selected_term.title,
          searchTerm: this.search
        })
      }
    },
    prevSigns() {
      if (this.selected_index > 0) {
        this.selected_index--
        track('prev_term', {
          selectedTerm: this.selected_term.title,
          searchTerm: this.search
        })
      }
    },
    checkSubscription() {
      this.$store.dispatch("auth/getSubscription").then(
        () => {
          if (this.isSubscribed) {
            trackAuthentication({ action: 'search:isSubscribed', user: this.$store.state.auth.user });
            // do nothing
          } else {
            trackAuthentication({ action: 'search:notSubscribed', user: this.$store.state.auth.user })
            this.$router.push("/subscribe")
          }
        },
        (error) => {
          trackAuthentication({ action: 'search:checkSubscriptionError', user: this.$store.state.auth.user });
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error)
          this.$router.push("/login")
        }
      )
    },
    async findSigns(term) {
      this.searchCompleted = false;
      term = term.trim()
      this.$router.push({ path: '/search', query: { q: term } })
      this.terms = []
      this.relatedTags = []

      /* Search terms results */
      if (term.length >= 1) {
        this.loading = true
        //console.log('Searching', term)
        track('search', { searchTerm: term })
        try {
          let response;
          if (this.searchOption === 'B') {
            response = await SignService.searchFollowedBy(term)
          }
          else {
            response = await SignService.search(term)
          }
          const { terms } = response.data
          console.log('terms', terms)

          track('searchResults', { searchTerm: term, resultsCount: terms.length })

          if (terms.length) {
            this.terms = response.data.terms
            window.scrollTo(0, 0);
          } else {
            track('searchNoResults', { searchTerm: term })
          }
        } catch (error) {
          console.log('Error:', error.message)
          trackError(error);
        }
      }
      /* End of Search terms results */

      /* Related Tags code */
      // Don't show related tags if user isn't permitted to see them
      this.relatedTags = []
      if (this.relatedTagsEnabled && term.length >= 1 || term.trim().toLowerCase() === "pickleball") {
        try {
          const relatedTagsResponse = await SignService.searchRelatedTags(term)
          const { related_tags: relatedTags } = relatedTagsResponse.data
          console.log('relatedTags', relatedTags)
          track('relatedTags', { searchTerm: term, resultsCount: relatedTags.length })
          this.relatedTags = relatedTags
        }
        catch (error) {
          console.log('Error:', error.message)
          trackError(error);
        }
      }
      /* End Related Tags code */
      this.loading = false
      this.searchCompleted = true
    },
    async getSuggestedTerms() {
      this.suggestedTerms = []
      try {
        const response = await SignService.getSuggestedTerms(this.search)
        const { suggested_terms: suggestedTerms } = response.data
        console.log('terms', suggestedTerms)
        this.suggestedTerms = suggestedTerms
      } catch (error) {
        console.log('Error:', error.message)
        trackError(error);
      }
    },
    navigateModal(event) {
      const moon = document.getElementById('moon');
      if (moon.classList.contains('show')) {
        if (event.which === 39) {
          this.nextSigns();
        }
        else if (event.which === 37) {
          this.prevSigns();
        }
      }
    },
    toggleLoop() {
      localStorage.setItem('videoloop', this.loop = !this.loop);
    }
  }
}
</script>

<style scoped>
.wts-btn-nav {
  display: grid;
  grid-template-columns: .5fr .5fr;
}

.wts-btn-prev,
.wts-btn-next {
  display: inline-grid;
}

.wts-btn-prev {
  grid-column: 1 / 2;
  justify-content: left;
}

.wts-btn-next {
  grid-column: 2 / 2;
  justify-content: right;
}
</style>
