<template>
  <div class="card mx-3 mb-4">
    <div
      v-if="show_form === 'verify_email'"
      class="card-body text-center"
    >
      <h4 class="mt-3 mb-4">
        Log in Fast
      </h4>
      <div class="mx-3">
        <p>
          We’re a password-free company! To start using What’s the Sign? web app, you can sign in using Single Sign-On with Apple or Google, or you can enter your email to receive a one-time code.
        </p>
      </div>
      <Form
        :validation-schema="email_schema"
        style="width: 18rem"
        class="mx-auto"
        @submit="sendCode"
      >
        <div class="form-floating mb-3">
          <Field
            name="email"
            type="email"
            placeholder="name@example.com"
            class="form-control mb-3"
          />
          <label>Email address</label>
          <ErrorMessage
            name="email"
            class="error-feedback"
          />
        </div>
        <div>
          <button
            class="btn btn-primary btn-block"
            style="width: 183px;"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Sign in with Email
          </button>
        </div>
      </Form>
      <div class="form-group mt-3">
        <div
          v-if="message"
          class="alert alert-danger"
          role="alert"
        >
          {{ message }}
        </div>
      </div>
      <div>
        <div class="row justify-content-md-center mx-5">
          <div class="text-center d-flex">
            <hr class="flex-grow-1 ms-5">
            <p
              class="text-center fw-light mt-2 mx-3"
              style="font-size: 12px;"
            >
              OR
            </p>
            <hr class="flex-grow-1 me-5">
          </div>
        </div>
        <div class="row">
          <div class="col" />
          <div class="col">
            <button
              class="btn btn-light btn-block border-dark text-nowrap"
              style="background-color: white;"
              @click="handleAppleSSO()"
            >
              <div class="d-flex align-items-center">
                <div
                  id="appleid-signin"
                  ref="appleSignInDiv"
                  class="signin-button"
                  data-color="white"
                  data-border="false"
                  data-type="sign-in"
                  data-mode="logo-only"
                  style="height:25px;"
                />
                <span class="ml-2">Sign in with Apple</span>
              </div>
            </button>
            <button
              class="btn btn-light btn-block border-dark text-nowrap mt-3"
              style="background-color: white;font-family: 'Roboto', sans-serif;"
              @click="signInWithGoogle()"
            >
              <div class="d-flex align-items-center">
                <img
                  src="/google-sso.svg"
                  alt="Sign in with Google"
                  style="margin-right: 8px;height:20px;"
                >
                <span>Sign in with Google</span>
              </div>
            </button>
          </div>
          <div class="col" />
        </div>
        <span
          v-show="appleLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    </div>
    <div
      v-if="show_form === 'verify_pin'"
      class="card-body text-center"
    >
      <h4 class="mt-3 mb-4">
        Check your email for a one-time code
      </h4>
      <span class="text-muted">Sent to {{ email }}</span>

      <div class="mt-3 mx-3">
        <p>
          First time here? Welcome! After you type in your one-time code, you’ll choose your plan,
          put in your card information, and then subscribe.
        </p>
      </div>
      <Form
        :validation-schema="otp_schema"
        @submit="handleLogin"
      >
        <div class="my-3">
          <div
            class="form-floating mx-auto"
            style="width:10rem"
          >
            <Field
              v-model="pin_code"
              maxlength="4"
              name="pin_code"
              type="text"
              placeholder="12TWA34"
              class="form-control mb-3 mx-auto"
              autocomplete="off"
            />
            <label class="mx-auto">One-time code</label>
          </div>
          <ErrorMessage
            name="pin_code"
            class="error-feedback"
          />
        </div>
        <div>
          <button
            class="btn btn-primary btn-block"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Login
          </button>
        </div>
      </Form>
      <p class="mt-3">
        Need support? Contact hello@whatsthesign.com
      </p>
      <div class="form-group mt-3">
        <div
          v-if="message"
          class="alert alert-danger"
          role="alert"
        >
          {{
            message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { trackAuthentication } from '@/helpers/google-analytics-tracker';
import { isIosAppUserAgent } from "@/helpers/ios-app";

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const email_schema = yup.object().shape({
            email: yup.string().required("Email address is required").email('Invalid email address')

        });
        const otp_schema = yup.object().shape({
            pin_code: yup
                .string()
                .required("One-time code is required")
                .min(4, "Enter 4 digits please"),
        });

        return {
            loading: false,
            show_form: 'verify_email',
            message: "",
            email: "",
            pin_code: "",
            email_schema,
            otp_schema,
            appleClientId: process.env.VUE_APP_APPLE_CLIENT_ID,
            redirectUrl: window.location.origin,
            nonce: "",
            appleLoading: false,
          tokenClient: null,
          googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isSubscribed() {
            return this.$store.state.auth.user.is_subscribed;
        },
        currentUserEmail() {
            return this.$store.state.auth.user.email;
        },
    },
  async mounted() {

        // Check if the event listener is already added
        if (!this.handleAppleIDSignInOnSuccess) {
            this.handleAppleIDSignInOnSuccess = (event) => {
                this.onAppleIDSignInSuccess(event);
            };
            document.addEventListener('AppleIDSignInOnSuccess', this.handleAppleIDSignInOnSuccess);
        }

        if (!this.handleAppleIDSignInOnFailure) {
            this.handleAppleIDSignInOnFailure = (event) => {
                this.onAppleIDSignInFailure(event);
            };
            document.addEventListener('AppleIDSignInOnFailure', this.handleAppleIDSignInOnFailure);
        }
        this.setupAppleAuth();
        this.setupGoogleAuth();
        if (this.$route.query?.email && this.$route.query?.magic_code) {
            console.log("route is  email, will show verify pin");
            this.email = this.$route.query.email;
            this.show_form = "verify_pin";
            this.pin_code = this.$route.query.magic_code;
            console.log(this.email, this.pin_code);
        }
    },
    unmounted() {
        if (this.handleAppleIDSignInOnSuccess) {
            document.removeEventListener('AppleIDSignInOnSuccess', this.handleAppleIDSignInOnSuccess);
            this.handleAppleIDSignInOnSuccess = null;
        }
        if (this.handleAppleIDSignInOnFailure) {
            document.removeEventListener('AppleIDSignInOnFailure', this.handleAppleIDSignInOnFailure);
            this.handleAppleIDSignInOnFailure = null;
        }
    },
    created() {
        if (this.loggedIn) {
            if (this.isSubscribed) {
                this.$router.push("/search");
            } else {
                this.$router.push("/subscribe");
            }
        }
    },
    methods: {
        sendCode(form) {
            this.loading = true;
            this.message = "";
            this.$store.dispatch("auth/sendCode", form).then(
                () => {
                    this.email = form.email;
                    this.show_form = "verify_pin";
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    this.email = "";
                    this.message =
                                    (error.response &&
                                      error.response.data &&
                                      error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                },
            );
        },
        async handleLogin(form) {
            this.message = "";
            this.loading = true;
            form["email"] = this.email;
            this.$store.dispatch("auth/login", form).then(
                () => {
                    const { isSubscribed } = this;
                    const redirectPath = localStorage.getItem('redirectPath');
                    localStorage.removeItem('redirectPath');
                    trackAuthentication({ email: this.email, action: 'login:success' });
                    if (redirectPath) {
                        this.$router.push(redirectPath);
                    } else if (isSubscribed) {
                        this.$router.push("/search");
                    } else {
                        this.$router.push("/subscribe");
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                                    (error.response &&
                                      error.response.data &&
                                      error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                    trackAuthentication({ action: 'login:failure', email: this.email });
                }
            );
        },
        async appleAuth(form) {
            this.appleLoading = true;
            this.message = "";
            this.$store.dispatch("auth/appleAuth", form).then(
                () => {
                    const { isSubscribed } = this;
                    const redirectPath = localStorage.getItem('redirectPath');
                    localStorage.removeItem('redirectPath');
                    trackAuthentication({ email: this.currentUserEmail, action: 'login:success' });
                    if (redirectPath) {
                        this.$router.push(redirectPath);
                    } else if (isSubscribed) {
                        this.$router.push("/search");
                    } else {
                        this.$router.push("/subscribe");
                    }
                    this.appleLoading = false;
                },
                (error) => {
                    this.appleLoading = false;
                    this.email = "";
                    this.message =
                                    (error.response &&
                                      error.response.data &&
                                      error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                },
            );
        },
        setupAppleAuth() {
            const stateLength = 32; // You can adjust the length as needed
            const state = this.generateRandomString(stateLength);
            const nonce = this.generateRandomString(stateLength);
            this.nonce = nonce;
            // Initialize Apple Sign In once the script has loaded
            try {
                window.AppleID.auth.init({
                    clientId: this.appleClientId,
                    scope: 'email',
                    redirectURI: this.redirectUrl,
                    state: state,
                    nonce: nonce,
                    usePopup: true,
                });
            } catch (error) {
                console.error('Error initializing Apple Sign In:', error);
            }
        },
        onAppleIDSignInSuccess(event) {
            // Your logic when Apple ID sign-in is successful
            console.log("AppleIDSignInOnSuccess");
            // email is in the JWT id_token
            let form = { code: event.detail.authorization.code, id_token: event.detail.authorization.id_token, state: event.detail.authorization.state, nonce: this.nonce };
            this.appleAuth(form);
        },
        onAppleIDSignInFailure(event) {
            // Handle error.
            console.log(event.detail.error);
        },
        generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
      },
      handleAppleSSO() {
        const targetElement = this.$refs.appleSignInDiv;
            // Trigger the click event on the target div
            if (targetElement) {
                targetElement.click();
            }
      }, 
      isIosAppUserAgent() {
        return isIosAppUserAgent();
    },
      setupGoogleAuth() {
        window.handleCredentialResponse = this.handleCredentialResponse.bind(this);
        window.authWtsIosApp = this.authWtsIosApp.bind(this);
        window.google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: this.handleCredentialResponse,
          ux_mode: 'popup',
          use_fedcm_for_prompt: true,
        });
      },

      async handleCredentialResponse(response) {
        if (response && response.status && response.status.error) {
          // Error handling
            console.error('Google Sign-In Error:', response.status.error);
        } else {
          // Successful Google sign-in,
          console.log("Google Sign-In Success");
          this.googleAuth({ credential: response.credential, is_wts_ios_app: false});
        }
    },

    signInWithGoogle() {
      console.log('signInWithGoogle');
      if (this.isIosAppUserAgent()) {
        // We are dispatching a custom event that can be received in the
        // WTS ios app to run google SSO with the GoogleSignIn iOS SDK
        var customEvent = new CustomEvent('signInWithGoogleEvent', {});
        // Dispatch the custom event
        document.dispatchEvent(customEvent);
      } else {
        try {
          if (this.$cookies.isKey('g_state')) {
            this.$cookies.remove('g_state');
          }
        } catch (error) {
          console.error('Error retrieving cookie:', error);
        }
        console.log("calling google accounts prompt");
        setTimeout(() => { window.google.accounts.id.prompt()}, 100)
      }

    },

    async googleAuth(form) {
      this.appleLoading = true;
      this.message = "";
      this.$store.dispatch("auth/googleAuth", form).then(() => {
        const { isSubscribed } = this;
        const redirectPath = localStorage.getItem('redirectPath');
        localStorage.removeItem('redirectPath');
        trackAuthentication({ email: this.currentUserEmail, action: 'login:success' });
        if (redirectPath) {
          this.$router.push(redirectPath);
        } else if (isSubscribed) {
          this.$router.push("/search");
        } else {
          this.$router.push("/subscribe");
        }
        this.appleLoading = false;
      },(error) => {
        this.appleLoading = false;
        this.email = "";
        this.message =
      (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
      },
  );
      },
      authWtsIosApp() {
        console.log("authWtsIosApp");
        var storedValue = localStorage.getItem("gToken");
        if (storedValue !== null && storedValue !== "") {
          // Value exists in localStorage, log it
          console.log(storedValue);
          this.googleAuth({ credential: storedValue, is_wts_ios_app: true });
          localStorage.removeItem("gToken");
        } else {
          // Value does not exist in localStorage
          console.log("Value not found in localStorage or was empty string");
        }
      },
  },
};
</script>
<style scoped>
.error-feedback {
  color: red;
}
</style>
