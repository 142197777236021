<template>
  <div id="app">
    <nav
      class="navbar navbar-expand-sm navbar-dark"
      style="background-color: #5F2C83"
    >
      <div style="height: env(safe-area-inset-top);">
        .
      </div>
      <div class="container-fluid">
        <a
          href="/"
          class="navbar-brand"
        >
          <img
            src="/wts.svg"
            alt="What's the Sign? logo"
            height="50"
            class="d-inline-block align-text-top"
          >
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-toggler-target"
          aria-controls="navbar-toggler-target"
          aria-expanded="false"
          aria-lebel="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          id="navbar-toggler-target"
          class="collapse navbar-collapse"
        >
          <ul
            v-if="showAdminBoard"
            class="navbar-nav me-auto mb-lg-0"
          >
            <li class="nav-item">
              <router-link
                to="/admin/dictionary"
                class="nav-link"
              >
                Edit Terms
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/admin/category"
                class="nav-link"
              >
                Edit Categories
              </router-link>
            </li>
            <li v-if="editTagsEnabled">
              <router-link
                to="/admin/tag"
                class="nav-link"
              >
                Edit Tags
              </router-link>
            </li>
            <template v-if="false">
              <li
                v-if="showModeratorBoard"
                class="nav-item"
              >
                <router-link
                  to="/mod"
                  class="nav-link"
                >
                  Moderator Board
                </router-link>
              </li>
              <li
                v-if="currentUser"
                class="nav-item"
              >
                <router-link
                  v-if="currentUser"
                  to="/user"
                  class="nav-link"
                >
                  User
                </router-link>
              </li>
            </template>
          </ul>
          <ul class="navbar-nav mb-2 ms-auto">
            <li
              v-if="currentUser"
              class="nav-item"
            >
              <a
                href="#"
                class="nav-link"
                @click="signOut"
              >Sign Out</a>
            </li>
            <li
              v-if="!currentUser"
              class="nav-item"
            >
              <a
                href="#"
                class="nav-link"
                @click="signIn"
              >Sign In</a>
            </li>

            <li class="nav-item">
              <a
                href="https://whatsthesign.com/blog/"
                class="nav-link"
              >Blog</a>
            </li>
            <li class="nav-item">
              <a
                href="https://whatsthesign.com/newsletter/"
                class="nav-link"
              >Newsletter</a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                @click="showFaq"
              >FAQ</a>
            </li>
            <template v-if="currentUser">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  @click="showAccount"
                >Account</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container pt-5">
      <div class="row">
        <div class="col-xs-1 col-md-2 col-lg-3" />
        <div class="col-xs-10 col-md-8 col-lg-6">
          <AlertSuccess />
          <AlertError />
          <router-view />
        </div>
        <div class="col-xs-1 col-md-2 col-lg-3" />
      </div>
    </div>
    <div class="fluid-container footer p-4 text-center text-muted small">
      Copyright &copy; 2023 What’s the Sign. All Rights Reserved.<br>
      <a
        class="ml-3 mr-2 text-decoration-none"
        href="https://whatsthesign.com/contact"
        target="_blank"
      >Contact</a> |
      <a
        class="ml-3 mr-2 text-decoration-none"
        href="https://whatsthesign.com/terms"
        target="_blank"
      >Terms of Use</a> |
      <a
        class="ml-2 text-decoration-none"
        href="https://whatsthesign.com/privacy"
        target="_blank"
      >Privacy Policy</a>
      <div class="pt-2">
        {{ token_info }}
        Version: {{ $store.getters.version }}
      </div>
    </div>
  </div>
</template>

<script>
import AlertSuccess from './components/AlertSuccess';
import AlertError from './components/AlertError';
import FeatureService from '@/services/feature.service';
import { trackAuthentication } from '@/helpers/google-analytics-tracker';
const MILLISECONDSIN60MINUTES = 60 * 60 * 1000;

export default {
  components: {
    AlertSuccess,
    AlertError,
  },
  data() {
    return {
      permitVisitorFAQ: false,
      editTagsEnabled: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      return this?.currentUser?.roles?.includes('admin');
    },
    showModeratorBoard() {
      return this?.currentUser?.roles?.includes('moderator');
    },
    token_info() {
      if (localStorage.access_token && localStorage.access_token.includes(".")) {
        let decoded = window.atob(localStorage.access_token.split('.')[1])
        let parsed = JSON.parse(decoded)
        let iat = new Date(parsed.iat * 1000).toLocaleString();
        let exp = new Date(parsed.exp * 1000).toLocaleString();
        return iat + '  to ' + exp
      } else {
        return ''
      }
    },
  },
  async mounted() {
    this.permitVisitorFAQ = await FeatureService.isEnabled("permit_visitor_faq");
    this.editTagsEnabled = await FeatureService.isEnabled("edit_tags");
    this.startVersionCheckTimer();
  },
  methods: {
    showFaq() {
      this.$router.push('/faq');
    },
    showAccount() {
      this.$router.push('/account');
    },

    signIn() {
      this.$router.push('/login');
    },

    signOut() {
      trackAuthentication({ user: this.$store.state.auth.user, action: 'logout' })
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    startVersionCheckTimer() {
      var count = 0;
      setInterval(() => {
        // if this is the first time loading the page don't reload.
        if (count == 0) {
          this.fetchVersion(false);
        }
        else {
          this.fetchVersion(true);
        }
        count++;
      }, MILLISECONDSIN60MINUTES); // 60 minutes
    },

    fetchVersion(reload) {
      fetch('/version.json')
        .then(response => response.json())
        .then(data => {
          if (this.$store.getters.version !== data.version) {
            this.$store.commit('setVersion', data.version);
            if (reload) {
              console.log("reloading...");
              window.location.reload(true);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching version data:', error);
        });
    },
  }
};
</script>

<style>
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}
</style>
