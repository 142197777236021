import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/'
const API_URL_WTS = process.env.VUE_APP_API_URL + '/wts/'

class UserService {
  getPublicContent() {
    return axios.get(API_URL_WTS + 'public');
  }

  getUserBoard() {
    return axios.get(API_URL_WTS + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL_WTS + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL_WTS + 'admin', { headers: authHeader() });
  }

  deleteAccount() {
    return axios.delete(API_URL + 'account', {headers: authHeader() });
  }

  cancelSubscriptionRenewal() {
    return axios.put(API_URL + 'account/cancel_renewal', {}, { headers: authHeader() });
  }

  getSubscriptionDetails () {
    return axios.get(API_URL + 'account/retrieve_subscription', { headers: authHeader() });
  }
}

export default new UserService();
