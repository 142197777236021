export async function sha1(str) {
  const encoded = (new TextEncoder()).encode(str)
  const data = await crypto.subtle.digest("SHA-1", encoded)
  const hashArray = Array.from(new Uint8Array(data)).map((b) => b.toString(16).padStart(2, "0"))
  return hashArray.join("")
}
export async function shortsha1(str) {
  return (await sha1(str)).slice(0, 5)
}
export default {
  sha1,
  shortsha1
};
