import axios from 'axios';
import authHeader from './auth-header';
import { shortsha1 } from '@/helpers/crypto.digest';
import { track, trackError } from '@/helpers/google-analytics-tracker';

const baseUrl = process.env.VUE_APP_API_URL;

class FeatureService {
  async isEnabled(featureName) {
    const feature = await this.getFeature(featureName);

    this.trackFeature(feature);
    return feature.enabled;
  }
  async getFeature(featureName) {
    try {
      const resp = await axios.get(`${baseUrl}/features/${featureName}`, { headers: authHeader() });
      const { feature } = resp.data;
      return feature;
    }
    catch (e) {
      trackError(e);
      return { enabled: false, name: featureName };
    }
  }
  async trackFeature(feature) {
    const userData = JSON.parse(localStorage.getItem('user'));
    const emailhash = userData?.email ? await shortsha1(userData.email) : '00000';
    track('feature', { name: feature.name, isEnabled: feature.enabled, emailhash });
  }
}

export default new FeatureService();
