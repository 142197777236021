const WTS_IOS_APP_UA = "WTS-iOS-app";

function isIosAppUserAgent() {
  return navigator.userAgent.includes(WTS_IOS_APP_UA);
}
    
function isNewerIosApp() {
    console.log("UA: " + navigator.userAgent);
    let ua = navigator.userAgent;
    if (!navigator.userAgent.includes(WTS_IOS_APP_UA)) {
      return false;
    }
    else {
      // this is WTS ios app
      // find build number if any
      console.log("this is wts ios app");
      let lastIndex = ua.lastIndexOf("-");
      if (lastIndex !== -1) {
        let secondPart = ua.substring(lastIndex + 1);
        if (secondPart.includes("app")) {
          console.log("returning false because last part is app without a build number, probably an old version of this WTS app")
          return false;
        }
        let n = parseInt(secondPart);
        if (isNaN(n)) {
          return false;
        }
        if (parseInt(secondPart) > 25) {
          console.log("returning true, build number is greater than 25");
          return true;
        } else {
          console.log("returning false, build number LESS or equal to 25");
          return false;
        }
      } else {
        return false;
      }
    }
  }

export {
    isIosAppUserAgent,
    isNewerIosApp,
  };
