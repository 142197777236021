import { shortsha1 } from '@/helpers/crypto.digest';

window.dataLayer = window.dataLayer || [];

export default function track(eventName, obj) {
  window.dataLayer.push({ event: eventName, ...obj });
}

/*** start of ecommerce tracking */
// It's recommended to clear the previous ecommerce object prior to pushing an ecommerce event to prevent multiple
// ecommerce events affecting each other
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
function clearEcommerceObject() {
  window.dataLayer.push({ ecommerce: null })
}

function trackSelectItem(ecommerce) {
  clearEcommerceObject()
  track("selectItem", { ecommerce });
}

function trackBeginCheckout(ecommerce) {
  clearEcommerceObject();
  track("beginCheckout", { ecommerce });
}

function trackPurchase(ecommerce) {
  clearEcommerceObject()
  track("purchase", { ecommerce })
}
/*** end of ecommerce tracking */

async function trackAuthentication({ action, user, email }) {
  const addr = email || user.email
  const emailhash = await shortsha1(addr);
  track('authenticationCheck', {
    action,
    emailhash,
  });
}

function trackError(err) {
  if (err.response) {
    const { data, status, headers } = err.response;
    track("error", { data: { data, status, headers }, type: "response" });
  } else if (err.request) {
    track("error", { data: { request: err.request }, type: "request" });
  } else if (err.message) {
    track("error", { data: { message: err.message }, type: "api" });
  } else {
    track("error", { data: { error: err}, type: "js" })
  }
}

export {
  track,
  trackAuthentication,
  trackError,
  trackSelectItem,
  trackBeginCheckout,
  trackPurchase,
};
